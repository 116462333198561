import { h } from 'preact';

import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

// Pages - Code-splitting is automated for `routes` directory
import Home, { pathName as rootPath } from '../routes/Home/Home';
import DownloadPlugin from '../routes/DownloadPlugin/DownloadPlugin';

import useDatadog from '../common/hooks/use-datadog';
import loadDependencies from '../dependency-injection/load-dependencies';
import { CoreModalPresenterProvider } from '@fanduel/core-modal-presenter';

import store from '../redux/store';
import { removeCookie } from "../common/helpers/cookies";
import { ACCOUNT_UM_COOKIES } from "../common/constants";

export const pathName = '/plugin';

const ShortLinkHome = () => {
    const { gameUid } = useParams();

    return <Home gameUid={gameUid} />;
};

const App = () => {
    loadDependencies();
    useDatadog();

    /** Account UM: This was added to make sure no date is stored in localStorage from the previous session **/
    localStorage.removeItem('session-casino');
    localStorage.removeItem('session-sportsbook');
    removeCookie(ACCOUNT_UM_COOKIES.ACCOUNT_SESSION);

    return (
        <ReduxProvider store={store}>
            <CoreModalPresenterProvider>
                <Router>
                    <Switch>
                        <Route exact path={rootPath} component={Home} />
                        <Route path={pathName} component={DownloadPlugin} />
                        <Route path="/:gameUid" component={ShortLinkHome} />
                    </Switch>
                </Router>
            </CoreModalPresenterProvider>
        </ReduxProvider>
    );
};

export default App;
