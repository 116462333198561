import { isRunningInsideWrapper, sendWrapperEvent } from '../wrapper-bridge-mobile';
import { MESSAGES } from '../constants';
import { getUserChannel } from './platform';
import { getRegion } from './cookies';
import config from '../../../config/config';
import { substituteJsonPlaceholders } from './jsonPlaceholders';

// desktop, close the window
// for mobile web, return to previous window
// for android, trigger android wrapper close flow (the X button at the top)
export const dismissLauncher = (msg, responsibleGamingLink) => {
    msg = msg || '';
    const isAndroidWrapper = isRunningInsideWrapper();
    const isMobileWeb = getUserChannel().toLowerCase() === 'phone';
    const variables = {
        region: getRegion()?.toUpperCase(),
        externalReferrerNext: encodeURIComponent(window.location.search)
    };
    const terms = substituteJsonPlaceholders(config?.termsAndConditionsUrl, variables);

    switch (true) {
    case msg.includes('CODE_414'):
    case msg.includes('USER_HAS_NOT_ACCEPTED_TERMS_AND_CONDITIONS'):
        window.location = terms;
        break;
    case msg.includes('responsible'):
        window.location = responsibleGamingLink;
        break;
    case isAndroidWrapper:
        sendWrapperEvent(MESSAGES.GAME_LAUNCHER_CLOSE, '');
        break;
    case isMobileWeb:
        history.back();
        break;
    default:
        window.close();
    }
};
