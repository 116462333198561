import { types } from '../../types';

export const initialState = {
    displayMenu: false
};

const displayMenuReducer = (state = initialState, action) => {
    switch (action.type) {
    case types.OPEN_MENU:
        return {
            displayMenu: true
        };
    case types.CLOSE_MENU:
        return {
            displayMenu: false
        };
    default: {
        return state;
    }
    }
};

export default displayMenuReducer;
