import { h, Fragment } from 'preact';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { useSelector } from 'react-redux';
import { FOCUS_TYPE, IMessageOverlay } from '../helpers/showoff.types';
import { CommonOverlay } from './Overlay';
import { Message } from './Message';
import { useShowoff } from '../useShowoff';

const RATIO_FOR_CHOOSING_SIDE = 1.4;

export const MessageOverlay = () => {
    const { elemRef, action, actionDismiss }: IMessageOverlay = useShowoff();
    const gameLoaded = useSelector((state: unknown) => state?.['gameWindow']?.gameLoaded);
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const [offsetTop, setOffsetTop] = useState(null);
    const [offsetLeft, setOffsetLeft] = useState(null);
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);

    useEffect(() => {
        if (gameLoaded && elemRef && elemRef?.element) {
            const { x, y, width: currentWidth, height: currentHeight } = elemRef.element.getBoundingClientRect();

            setOffsetTop(y);
            setOffsetLeft(x);
            setHeight(currentHeight);
            setWidth(currentWidth);
        }
    }, [elemRef, gameLoaded]);

    // sizes for the message popup and positioning
    const messageSizes: { top?: number; left?: number; right?: number; bottom?: number } = useMemo(() => {
        const marginToRight = windowWidth - (offsetLeft + width);
        const marginToBottom = windowHeight - (offsetTop + height);
        let sizes: { top?: number; left?: number; right?: number; bottom?: number } = {};
        if (marginToRight * RATIO_FOR_CHOOSING_SIDE > offsetLeft) {
            sizes = { ...sizes, left: offsetLeft + width };
        } else {
            sizes = { ...sizes, right: windowWidth - offsetLeft };
        }

        if (marginToBottom * RATIO_FOR_CHOOSING_SIDE > offsetTop) {
            sizes = { ...sizes, top: offsetTop };
        } else {
            sizes = { ...sizes, bottom: windowHeight - offsetTop };
        }

        return sizes;
    }, [windowWidth, offsetLeft, width, windowHeight, offsetTop, height]);

    // 3 flows:
    // NOTHING: literally nothing
    // OVERLAY: all page overlay + message popup
    // BOX: gapped overlay for the current element to be visible + popup
    return (
        gameLoaded &&
        elemRef?.element &&
        elemRef.focusType !== FOCUS_TYPE.NOTHING && (
            <div
                className={`overlay-wrapper`}
                style={{
                    background: elemRef.focusType === FOCUS_TYPE.OVERLAY ? 'rgba(22, 39, 64, .6)' : '',
                    zIndex: elemRef.focusType === FOCUS_TYPE.OVERLAY ? '3' : ''
                }}
                onClick={e => {
                    e.preventDefault();
                    actionDismiss();
                }}
            >
                <Message {...messageSizes} action={action} actionDismiss={actionDismiss} />
                {elemRef.focusType === FOCUS_TYPE.BOX && (
                    <Fragment>
                        <CommonOverlay offsetTop={offsetTop} offsetLeft={offsetLeft} height={height} width={width} />
                    </Fragment>
                )}
            </div>
        )
    );
};
