import { CorePx } from '@fanduel/core-px';
import { CoreConfig } from '@fanduel/core-config';
import CoreDeepLinks from '@fanduel/core-deeplinks';
import { getRegion } from '../common/helpers/cookies';
import { getBrandUM, getCountry, getEnvironment } from '../common/helpers/unified-modules';
import { checkValidSession, initializeAccountUM, subscribeSession } from './account-library';
import { getDecodedClientID } from './utils';
import { ModalPresenter } from '@fanduel/core-modal-presenter';
import { CoreApiIdentities } from '@fanduel/core-api-identities';
import { Config, Product } from '@fanduel/core-config-contract';
import { CoreWebViewPluginRegistry } from '@fanduel/core-webview';
import config from '../../config/config';

let initialized = false;

export const initializeUnifiedModules = async () => {
    if (initialized) {
        console.warn("Unified modules already initialized.");
        return;
    }

    CoreWebViewPluginRegistry.add({
        id: 'show-header',
        onUrlLoaded: (ref) => {
            ref.updateConfig((conf) => ({
                ...conf,
                showHeader: true,
                showFooter: true
            }))
        }
    })

    const decodedClientID = getDecodedClientID(config.clientID);
    const product = process.env.PRODUCT;

    const conf: Config = {
        appDomain: {
            product: Product.Casino,
            region: getRegion(),
            brand: getBrandUM(process.env.BRAND)
        },
        clientAuthToken: decodedClientID,
        environment: {
            type: getEnvironment(),
            country: getCountry(process.env.COUNTRY)
        }
    }

    const xsell = 'xsell';
    if (product === xsell) {
        conf.appDomain = {
            product: Product.Sportsbook,
            region: getRegion()?.toLowerCase?.()
        }
    }

    await CoreConfig.setConfig(conf);
    await CorePx.initialize(true);
    await CoreDeepLinks.initialize();
    await initializeAccountUM();
    CoreApiIdentities.initialize();
    ModalPresenter.initialize();

    await checkValidSession();
    await subscribeSession();
    initialized = true;
    return true;
};
