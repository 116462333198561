import { h, Fragment } from 'preact';
import { useSelector } from 'react-redux'

import PointsLoader from '../../assets/icons/general/points-loader'
import useLoading from '../../common/hooks/useLoading';

require(`./styles/${process.env.APPLICATION}/style.scss`);

const Loader = ({ customClass = 'loader' }) => {
    const isLoading = useSelector(state => state?.gameData?.loading);
    const { lastRequest } = useLoading();
    return (
        <Fragment>
            {isLoading && (
                <div className={customClass}>
                    <PointsLoader />
                    <p>{lastRequest?.name || 'loading game'}</p>
                </div>
            )}
        </Fragment>
    )
}

export default Loader
