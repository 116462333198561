export const SWJ_ERROR_TYPES_AND_CODES = {
    'SWJ-001': 'opt_in_initialization_failure',
    'SWJ-002': 'opt_in_failure',
    'SWJ-003': 'opt_out_failure',
    'SWJ-004': 'opt_in_lobby_sync',
    'SWJ-101' : 'jackpot_ticker_initialization_failure',
    'SWJ-102' : 'jackpot_ticker_time_out',
    'SWJ-201' : 'MDS_initialization_failure',
    'SWJ-202' : 'MDS_keep_alive',
    'SWJ-203' : 'SWJ_enabled',
    'SWJ-204' : 'SWJ_disabled',
    'SWJ-205' : 'game_added_to_swj',
    'SWJ-206' : 'game_removed_from_swj'
} as const;

export const getErrorTypeAmplitudeValue = (errorType: string, errorCode: string): string | undefined => {
    return errorType !== 'swj_service_error' ? errorType : SWJ_ERROR_TYPES_AND_CODES[errorCode];
};
