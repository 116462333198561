// import { datadogRum } from '@datadog/browser-rum';
import { AW_COOKIE } from '../../../../common/constants';
import { types } from '../../../types';
import Cookie from 'js-cookie';
import axiosAWInstance from '../../../../common/axios/AWAxios';
import config from '../../../../../config/config';
import { datadogRum } from '@datadog/browser-rum';
import connectionType from '../../../../common/helpers/connectionType';

export const getUserFreeBets = (initialFetch) => async (dispatch) => {
    if (initialFetch) {
        dispatch({ type: types.GET_USER_FREE_BETS_REQUEST });
    }
    const authToken = Cookie.get(AW_COOKIE.AUTH_TOKEN) || null;

    await axiosAWInstance
        .get(config?.accountAndWalletApi?.endpoints?.USER_FREE_BETS, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Basic ${config.clientID}`,
                'Cache-Control': 'no-store, no-cache',
                [AW_COOKIE.AUTH_TOKEN]: authToken
            }
        })
        .then(response => {
            if (response) {
                //if the new wallet feature is enabled it should contain context_rules, otherwise we add 0
                if (response?.data?.wallets[0]?.context_rules) {
                    const sumOfFreeBets = response?.data?.wallets?.reduce((acc, wallet) => {
                        if (wallet?.context_rules[0]?.right_operand.includes('CASINO')) {
                            return acc + wallet?.balance;
                        }
                        return acc;
                    }, 0);
                    dispatch({
                        type: types.GET_USER_FREE_BETS_RECEIVED,
                        payload: sumOfFreeBets
                    });
                } else {
                    dispatch({
                        type: types.GET_USER_FREE_BETS_RECEIVED,
                        payload: 0
                    });
                }

            }
        })
        .catch(error => {
            dispatch({
                type: types.GET_USER_FREE_BETS_FAILED
            });

            datadogRum.addError(
                new Error(error),
                Object.assign(
                    {
                        type: '[GET_USER_FREE_BETS]: ERROR',
                        connectionType: connectionType()
                    },
                    error
                ),
            );
        });
}

export default getUserFreeBets;
