import { PLATFORM } from '../../../common/constants/game-constants';
import sendToApptentiveAndroid from './send-apptentive-android';
import sendToAlchemerXsell from './send-alchemer-xsell';
import VoidService from '../../../common/helpers/void-service';

const voidServiceWeb = () => VoidService

const sendApptentiveByPlatformMap = {
    [PLATFORM.Desktop]: voidServiceWeb,
    [PLATFORM.Android]: sendToApptentiveAndroid,
    [PLATFORM.Xsell]: sendToAlchemerXsell,
    [PLATFORM.Ios]: voidServiceWeb,
}
/**
 * send to apptentive factory by platform context
 *
 * @param {string} platform
 * @returns {function(...[*]): function(*): Promise<void>} current send to apptentive action creator
 */
export default function sendApptentiveFactory(platform) {
    return function sendToApptentive(...options) {
        return sendApptentiveByPlatformMap[platform](...options)
    }
}
