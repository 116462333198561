import { h, Fragment } from 'preact';
import { useCallback, useContext } from 'preact/hooks';
import { useSelector } from 'react-redux';

import Timers from '../../../Timers/Timers';
import LogoIcon from '../../../../assets/icons/GameWindow/LogoIcon';
import Menu from '../Menu/Menu';
import ExitButton from '../ExitButton/ExitButton';
import {
    depositLinkSelector,
    gameWindowExpansionStateSelector,
    showDepositButtonSelector,
    showSoundButtonSelector
} from '../../../../selectors/game-window';
import WalletBalance from '../WalletBalance/WalletBalance';
import SoundToggle from '../SoundToggle/SoundToggle';
import { GameWindowContext } from '../../GameWindow';
import { useLogTracking } from '../../../../common/hooks/use-user-tracking/useUserTracking';
import { MODULES, ZONES, getAmplitudeKey } from '../../../../common/hooks/use-user-tracking/types';
import { AddFunds } from '../../../AddFunds/AddFunds';
import { TopBar } from '../SWJ/TopBar/TopBar';
import { MinMaxGameToggle } from '../../../MinMaxGameToggle/MinMaxGameToggle';

const Panel = ({ isSwjEnabled, jackpotId, position }) => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);

    const isShowingTopBar = useSelector(gameWindowExpansionStateSelector);
    const { logTrackingComponent } = useLogTracking();
    const { deviceType, isPortrait, toggleAudio, showGameWindow } = useContext(GameWindowContext);
    const isMenuOpen = useSelector(state => state?.displayMenu?.displayMenu);
    const showDepositButton = useSelector(showDepositButtonSelector);
    const depositLink = useSelector(depositLinkSelector);
    const showSoundButton = useSelector(showSoundButtonSelector);
    const addFundsEnabled = showGameWindow && depositLink;

    const sendGtmMessage = useCallback(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'In Game Exited',
            [getAmplitudeKey('MODULE')]: MODULES.IN_GAME_HEADER,
            [getAmplitudeKey('ZONE_NAME')]: ZONES.IN_GAME
        });
    }, [logTrackingComponent]);

    const timer = (
        <Timers
            isPortrait={isPortrait}
            menu={isMenuOpen}
            isLandscape={deviceType === 'landscape' || deviceType === 'desktop'}
        />
    );

    const logo = <LogoIcon isPortrait={isPortrait} isMenuOpen={isMenuOpen} isSwjEnabled={isSwjEnabled} />;
    const verticalLine = showSoundButton ? <div className="vertical-divider" /> : <Fragment />;
    const sound = showSoundButton ? (
        <SoundToggle
            {...{
                toggleAudio,
                width: deviceType === 'desktop' ? '30' : '27',
                height: deviceType === 'desktop' ? '22' : '20'
            }}
        />
    ) : null;
    const mobileMenuItems = (
        <Fragment>
            <span>{<WalletBalance deviceType={deviceType} />}</span>
            {sound}
            {verticalLine}
        </Fragment>
    );

    const swjPanel = () => {
        return (
            isSwjEnabled && jackpotId ?
                <Fragment>
                    <div className={`items-${isMenuOpen ? 'menu-open' : 'menu-closed'}`}>
                        {jackpotId && <TopBar jackpotId={jackpotId} display={!isMenuOpen} />}
                    </div>
                </Fragment> :
                null
        );
    }

    const renderTop = () => (
        <Fragment>
            {showGameWindow && <Menu />}
            <div className={`items-${isMenuOpen ? 'menu-open' : 'menu-closed'}`}>
                {isMenuOpen && mobileMenuItems}
                {jackpotId && isSwjEnabled ?
                    <TopBar jackpotId={jackpotId} display={!isMenuOpen} />
                    : logo
                }
            </div>
            <ExitButton direction="row" size="17" onClick={sendGtmMessage} zoneName={ZONES.IN_GAME} />
        </Fragment>
    );

    const renderLeft = () => (
        <Fragment>
            {showGameWindow && <Menu />}
            {timer}
        </Fragment>
    );

    const renderRight = () => (
        !isMenuOpen && (
            <Fragment>
                <ExitButton direction="column" size="21" onClick={sendGtmMessage} zoneName={ZONES.IN_GAME} />
                {isSwjEnabled && jackpotId && <MinMaxGameToggle />}
                {addFundsEnabled && (
                    <AddFunds
                        deviceType={deviceType}
                        showDepositButton={showDepositButton}
                        depositLink={depositLink}
                        wrapperClassName="add-funds-wrapper landscape"
                    />
                )}
            </Fragment>
        )
    );

    const renderBottom = () => (
        !isMenuOpen && (
            <Fragment>
                {isSwjEnabled && logo}
                {timer}
                {addFundsEnabled && (
                    <AddFunds
                        deviceType={deviceType}
                        showDepositButton={showDepositButton}
                        depositLink={depositLink}
                        wrapperClassName="add-funds-wrapper portrait"
                    />
                )}
            </Fragment>
        )
    );

    const render = () => {
        switch (position) {
        case 'top':
            return renderTop();
        case 'top swj':
            return swjPanel();
        case 'left':
            return renderLeft();
        case 'right':
            return renderRight();
        case 'bottom':
            return renderBottom();
        default:
            return null;
        }
    };

    return <div className={`panel-${position} ${!isShowingTopBar && 'hide-top-bar'} ${isMenuOpen ? 'menu-active' : ''}`}>{render()}</div>;
};

export default Panel;
