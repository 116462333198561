import { h } from 'preact';
import { useDispatch, useSelector } from 'react-redux';

import { GEOCOMPLY_ERROR_TYPES, RGIS } from '../../../common/constants';
import { isRunningInsideWrapper } from '../../../common/wrapper-bridge-mobile';
import GeoValidatorNotInstalled from './geoValidatorNotInstalled/GeoValidatorNotInstalled';
import LocationError from './location-error/location-error';
import NotAllowed from './not-allowed/not-allowed';
import CheckingLocation from './checking-location/checking-location';
import LocationNote from './location-note/location-note';
import FooterNote from './footer-note/footer-note';
import { ModalWrapper } from './modal-wrapper/modal-wrapper';
import { gameLoadedSelector, rgiSelector } from '../../../selectors/gameContext';
import { useEffect } from 'preact/hooks';
import { types } from '../../../redux/types';
import { createPortal } from 'react-dom';

require(`./styles/${process.env.APPLICATION}/style.scss`);

const getModalContent = ({
    showGeoValidatorNotInstalled,
    isLocationGeneralError,
    isLocationValidationError,
    isRetrying
}) => {
    switch (true) {
    case showGeoValidatorNotInstalled:
        return GeoValidatorNotInstalled;
    case isLocationGeneralError:
        return LocationError;
    case isLocationValidationError:
        return NotAllowed;
    case isRetrying:
        return CheckingLocation;
    default:
        return CheckingLocation;
    }
};

const GeoComplyWeb = () => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);

    const dispatch = useDispatch();

    const locationError = useSelector(state => state?.geocomply?.location?.error);
    let isLocationGeneralError = locationError?.type === GEOCOMPLY_ERROR_TYPES.GENERAL;
    const isLocationValidationError = locationError?.type === GEOCOMPLY_ERROR_TYPES.VALIDATION;
    const plcError = useSelector(state => state?.plc?.error);
    const mobileError = useSelector(state => state?.plc?.mobileError);
    const androidWakeupUrl = useSelector(state => state?.plc?.androidWakeupUrl);
    const iosWakeupUrl = useSelector(state => state?.plc?.iosWakeupUrl);
    const isRetrying = useSelector(state => state?.geocomply?.location?.retrying);

    const rgi = useSelector(rgiSelector);
    const plcInstalled = useSelector(state => state?.plc?.installed);

    const gameLoaded = useSelector(gameLoadedSelector);
    const gameDataLoaded = useSelector(
        state => (rgi === RGIS.FGP ? state?.fgp?.data : state?.gameData?.data) || gameLoaded
    );

    isLocationGeneralError = isLocationGeneralError || plcError !== null;

    const showGeoValidatorNotInstalled = mobileError || (isRunningInsideWrapper() ? false : plcInstalled === false);

    const ModalContent = getModalContent({
        showGeoValidatorNotInstalled,
        isLocationGeneralError,
        isLocationValidationError,
        isRetrying
    });

    // we need an hierarchy of errors;
    // plcInstalled is the most important one
    // locationError and plcError are the 2nd most important ones
    // checking location and not allowed are the 3rd most important ones
    // when game is loaded we need to get rid of the footer

    const isLocationError = !showGeoValidatorNotInstalled && (locationError !== null || plcError !== null);

    useEffect(() => {
        if (isLocationError) {
            dispatch({ type: types.SET_LOADING_SCREEN_VISIBILITY, payload: false });
        }
    }, [dispatch, isLocationError]);

    return (!gameDataLoaded || isLocationError || isRetrying) ? (
        createPortal(
            <ModalWrapper
                bottom={
                    <div class="bottom-container">
                        {!showGeoValidatorNotInstalled && !isLocationError && <LocationNote />}
                        <FooterNote />
                    </div>
                }
            >
                <ModalContent androidWakeupUrl={androidWakeupUrl} iosWakeupUrl={iosWakeupUrl} />
            </ModalWrapper>,
            document.body)
    ) : null;
};

export default GeoComplyWeb;
