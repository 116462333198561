import { useSelector } from 'react-redux';
import { ERROR_TYPE, FOOTER_TYPES } from '../../../game-window/contants/errors';
import { inGameErrorSelector } from '../../../selectors/game-window';
import { getRegion } from '../../../common/helpers/cookies';
import { getIconRegion } from '../../../common/helpers/get-icon-region';

const GenericFooter = ({ isOutOfFundsError, onClickHandler, deviceType }) => {
    const { firstMessage, actionMessage } = isOutOfFundsError
        ? FOOTER_TYPES[ERROR_TYPE.INSUFFICIENT_FUNDS].messages
        : FOOTER_TYPES[ERROR_TYPE.GENERIC].messages;
    const { errorCode } = useSelector(inGameErrorSelector);
    const region = getRegion();

    return (
        <div className={`generic-popup-footer ${deviceType}`}>
            {isOutOfFundsError && (
                <div className="footer-icon">
                    {getIconRegion(region)}
                </div>
            )}
            <div className="footer-text">
                <p>
                    {firstMessage}
                    <span className="action-message" onClick={onClickHandler}>
                        {actionMessage}
                    </span>
                    {!isOutOfFundsError && <span> {`(Error ${errorCode})`} </span>}
                </p>
            </div>
        </div>
    );
};

export default GenericFooter;
