import { types } from '../../types'

export const initialState = 0;

const freeBetsReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
    case types.GET_USER_FREE_BETS_REQUEST:
    case types.GET_USER_FREE_BETS_FAILED:
        return state

    case types.GET_USER_FREE_BETS_RECEIVED:
        return payload

    default: {
        return state
    }
    }
}

export default freeBetsReducer;
