import { useCallback, useMemo, useEffect } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { getTimezone } from 'casino-fabric-l10n/lib/localization-helper';

import { getRegion } from '../helpers/cookies';
import { setCurrentTimeAction } from '../../redux/actions/game-window';
import { TIMERS_TIMESTAMPS } from '../constants';

dayjs.extend(utc);
dayjs.extend(timezone);

const REGION = getRegion()?.toUpperCase();
const TIMEZONE = getTimezone('US', REGION === 'MR' ? 'CT' : REGION);
const { SERVER_MARK, CURRENT_MEASURE } = TIMERS_TIMESTAMPS;

export const useCurrentTime = tickerInitialized => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const currentTimeFormat = t('timerFormat.currentTime');
    const serverTime = useSelector(state => state?.session?.serverTime);

    const serverTimeValue = useMemo(() => {
        if (serverTime?.length > 0) {
            return new Date(serverTime).valueOf();
        }
    }, [serverTime]);

    const updateCurrentTime = useCallback(() => {
        if (window?.performance?.getEntriesByName?.(SERVER_MARK)?.length > 0 && serverTimeValue !== undefined) {
            window?.performance?.clearMeasures?.(CURRENT_MEASURE);
            window?.performance?.measure?.(CURRENT_MEASURE, SERVER_MARK);
            const timeElapsed = window?.performance?.getEntriesByName?.(CURRENT_MEASURE)?.[0]?.duration || 0;
            const timeNow = serverTimeValue + timeElapsed;
            dispatch(setCurrentTimeAction(dayjs(timeNow).tz(TIMEZONE).format(currentTimeFormat)));
        }
    }, [currentTimeFormat, dispatch, serverTimeValue]);

    useEffect(() => {
        if (tickerInitialized) {
            const interval = setInterval(updateCurrentTime, 1000);

            return () => {
                clearInterval(interval);
                window?.performance?.clearMeasures?.(CURRENT_MEASURE);
            };
        }
    }, [serverTimeValue, tickerInitialized, updateCurrentTime]);
};
