import { datadogRum } from "@datadog/browser-rum";

export type ColorValueHex = `#${string}`;


export const redactSensitiveToken = (token: string | null) => {
    const tokenLength = token?.length;

    if (!token || !tokenLength) {
        return '';
    }

    return token.slice(0, 4) + '*'.repeat(6) + token.slice(tokenLength - 4);
};


class Logger {
    private readonly color: string;
    private readonly prefix: string;
    private destination: any;
    static DEFAULT_COLOR = '#019310';
    static DEFAULT_PREFIX = '[AccountManager]';

    constructor(prefix: string, color: ColorValueHex, logToDataDog = false) {
        this.color = color || Logger.DEFAULT_COLOR;
        this.prefix = prefix || Logger.DEFAULT_PREFIX;

        this.destination = logToDataDog ? this.datadogLogger : console
    }

    private datadogLogger = {
        debug: (message: string) => datadogRum.addAction(message),
        info: (message: string) => datadogRum.addAction(message),
        warn: (message: string) => datadogRum.addAction(message),
        error: (message: string) => datadogRum.addAction(message),
        log: (message: string) => datadogRum.addAction(message),
    }

    private formatMessage = (message: string) => `%c${this.prefix} ${message}`;
    private getConsoleColor = () => `color: ${this.color};`;
    debug = (message: string, ...options: unknown[]) => this.destination
        .debug(this.formatMessage(message), ...options, this.getConsoleColor());
    info = (message: string, ...options: unknown[]) => this.destination
        .info(this.formatMessage(message), ...options, this.getConsoleColor());
    warn = (message: string, ...options: unknown[]) => this.destination
        .warn(this.formatMessage(message), ...options, this.getConsoleColor());
    error = (message: string, ...options: unknown[]) => this.destination
        .error(this.formatMessage(message), ...options, this.getConsoleColor());
    log = (message: string, ...options: unknown[]) => this.destination
        .log(this.formatMessage(message), ...options, this.getConsoleColor());
}


export const getAccountLogger = (logToDataDog = false) => new Logger('[AccountManager]', '#019310', logToDataDog);
