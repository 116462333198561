import { useEffect } from 'preact/hooks';
import { MDS } from '@fanduel/casino-swj-consumer';
import { getMDSEndpoint } from '../../../../../../common/helpers/get-mds-endpoint';
import { datadogLogs } from '@datadog/browser-logs';
import { setJackpotWin } from '../../../../../../redux/reducers/site-wide-jackpot/site-wide-jackpot';
import { useDispatch, useSelector } from 'react-redux';
import { swjTiersSelector } from '../../../../../../selectors/site-wide-jackpot';
import { ERROR_TYPE } from '../../../../../../game-window/contants/errors';
import { setGameWindowSWJOptIn, setInGameError } from '../../../../../../redux/actions/game-window';
import { useLogTracking } from '../../../../../../common/hooks/use-user-tracking/useUserTracking';
import { getAmplitudeKey, MODULES } from '../../../../../../common/hooks/use-user-tracking/types';

export const useMDS = (jackpotId, setOptedIn, optedInOption) => {
    const dispatch = useDispatch();
    const swjTiers = useSelector(swjTiersSelector);
    const userId = useSelector(state => state?.['session']?.userId);
    const { logTrackingComponent } = useLogTracking();


    const { initSocket } = MDS(getMDSEndpoint(), message => {
        const {
            data: {
                addedMessage: { type, subtype, payload }
            }
        } = message;

        try {
            const jsonPayload = JSON.parse(payload);

            if (type === 'JACKPOT' && subtype === 'WIN') {
                const tier = swjTiers[jsonPayload.jackpotTierId];
                logTrackingComponent({
                    [getAmplitudeKey('EVENT')]: 'FDC Jackpot Won',
                    [getAmplitudeKey('FDC_JACKPOT_OPT_IN_STATUS')]: `opted_${optedInOption}`,
                    [getAmplitudeKey('MODULE')]: MODULES.JACKPOT_WIN,
                    [getAmplitudeKey('FDC_JACKPOT_WON_TIER')]: tier?.shortName?.toLowerCase(),
                    [getAmplitudeKey('FDC_JACKPOT_WON_AMOUNT')]: jsonPayload?.amount
                });
                datadogLogs.logger.info('[MDS][EVENTS]jackpots_messages_listener_receive', {
                    jackpotId,
                    jackpotTierId: jsonPayload.jackpotTierId,
                    amount: jsonPayload.amount,
                    name: jsonPayload.name,
                });

                dispatch(
                    setJackpotWin({
                        id: jsonPayload.jackpotTierId,
                        amount: jsonPayload.amount,
                        shortName: tier.shortName,
                        name: tier.name,
                        win_particles_animation: tier?.win_particles_animation?.url,
                        win_spin_animation: tier?.win_spin_animation?.url,
                    })
                );
            }

            if (type === 'JACKPOT' && subtype === 'OPT_IN_STATUS') {
                const { optedIn, jackpotId: optedJackpot } = jsonPayload;
                //jackpotid, status (opt in/out)
                if (optedJackpot === jackpotId) {
                    setOptedIn(optedIn);
                    setGameWindowSWJOptIn(optedIn);
                }
            }

            if (type === 'ERROR') {
                logMDSInitError(jsonPayload);
                dispatch(
                    setInGameError({
                        errorCode: jsonPayload.errorCode,
                        errorMessage: jsonPayload.message,
                        errorType: ERROR_TYPE.SWJ_SERVICE_ERROR
                    })
                );
            }
        } catch (error) {
            console.warn('Error parsing MDS message', error);
            logMDSInitError(error);
        }
    });

    const initializeMDS = () => {
        try {
            datadogLogs.logger.info('[MDS][EVENTS]jackpots_messages_listener_initiated', {
                jackpotId
            });
            userId && initSocket(userId);
            datadogLogs.logger.info('[MDS][EVENTS]jackpots_messages_listener_completed', {
                jackpotId
            });
        } catch (error) {
            logMDSInitError(error);
            dispatch(setInGameError({
                errorCode: 'SWJ-201',
                errorMessage: error?.message,
                errorType: ERROR_TYPE.SWJ_SERVICE_ERROR,
            }));
        }
    }

    const logMDSInitError = (error) => {
        datadogLogs.logger.error('[MDS][EVENTS]jackpots_messages_failed_to_init_appsync_client',
            Object.assign(
                {
                    type: '[MDS][EVENTS]jackpots_messages_failed_to_init_appsync_client',
                },
                error,
                jackpotId
            )
        );
    }

    useEffect(() => {
        if (userId) {
            initializeMDS();
        }
    }, [userId]);
};
