import {
    isCurrentLocalSessionValid,
    clearSession,
    getLoginToken,
    getSession
} from '../../../unified-modules/account-library';
import { AW_COOKIE, MESSAGES } from '../../constants';
import { removeAWCookies, setAWCookies } from '../../helpers/cookies';
import { clearSessionAndRedirectToLogin } from '../../helpers/clearSessionAndRedirectToLogin';
import {
    sendIosSessionExpiredMessage,
    isRunningInsideV3Wrapper,
    isRunningInsideWrapper,
    sendWrapperEvent,
    sendIosTokenRefreshMessage
} from '../../wrapper-bridge-mobile';

export const sessionExpirationRequestInterceptor = axiosInstance => {
    const interceptor = axiosInstance.interceptors.response.use(
        requestResponse => requestResponse,
        async requestError => {
            const status = requestError?.response?.status;
            if (status !== 401) {
                return Promise.reject(requestError);
            }

            const validSession = await isCurrentLocalSessionValid();
            axiosInstance.interceptors.response.eject(interceptor);

            if (validSession) {
                const session = await getSession();
                const authToken = session.token;
                const loginToken = await getLoginToken();

                if (session) {
                    axiosInstance.defaults.headers[AW_COOKIE.AUTH_TOKEN] = session.token;
                    requestError.response.config.headers[AW_COOKIE.AUTH_TOKEN] = session.token;

                    sendIosTokenRefreshMessage({
                        authToken,
                        loginToken
                    });

                    setAWCookies(authToken, loginToken);

                    return axiosInstance(requestError.response.config);
                }
            }

            if (isRunningInsideWrapper() || isRunningInsideV3Wrapper()) {
                removeAWCookies();
                clearSession();
                sendWrapperEvent(MESSAGES.GAME_LAUNCHER_CLOSE, '');
                sendIosSessionExpiredMessage();
            } else {
                const hadActiveSession = !!sessionStorage.getItem('hadActiveSession');
                if (hadActiveSession) {
                    sessionStorage.setItem('hadActiveSession', false)
                    clearSessionAndRedirectToLogin();
                }
            }

            // Re-inject the response interceptor
            sessionExpirationRequestInterceptor(axiosInstance);
        }
    );
};
