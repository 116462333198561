import { useSelector } from 'react-redux';
import { useContext } from 'preact/hooks';

import { menuItemsSelector } from '../../../../selectors/game-window/content';
import MenuButton from './components/MenuButton';
import MenuToggle from './components/MenuToggle';
import { GameWindowContext } from '../../GameWindow';

const Menu = () => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);

    const { isPortrait } = useContext(GameWindowContext);
    const isMenuOpen = useSelector(state => state?.displayMenu?.displayMenu);
    const menuItems = useSelector(menuItemsSelector);

    return (
        !!menuItems.length && (
            <div className={`menu-component ${isPortrait ? 'portrait' : 'landscape'}`}>
                <MenuButton isPortrait={isPortrait} />
                {isMenuOpen && <MenuToggle />}
            </div>
        )
    );
};

export default Menu;
