import { PLATFORM } from '../../../../common/constants/game-constants';
import { getUserFreeBets } from './get-user-free-bets';

export const getUserFreeBetsByPlatform = {
    [PLATFORM.Desktop]: getUserFreeBets,
    [PLATFORM.Android]: getUserFreeBets,
    [PLATFORM.Xsell]: getUserFreeBets,
    [PLATFORM.Ios]: getUserFreeBets
};

/**
 * get common ui data by platform context
 *
 * @param {string} platform
 * @returns {(function(): function(*): Promise<void>)} get common ui data action creator
 */
export default function getUserFreeBetsFactory(platform) {
    return getUserFreeBetsByPlatform[platform];
}
