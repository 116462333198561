export const types = {
    // --- Account and Wallet types --- //

    // session
    GET_CURRENT_SESSION_DETAILS_REQUEST: 'getCurrentSessionDetails/REQUEST',
    GET_CURRENT_SESSION_DETAILS_RECEIVED: 'getCurrentSessionDetails/RECEIVED',
    GET_CURRENT_SESSION_DETAILS_FAILED: 'getCurrentSessionDetails/FAILED',
    GET_USER_DETAILS_REQUEST: 'getUserDetails/REQUEST',
    GET_USER_DETAILS_RECEIVED: 'getUserDetails/RECEIVED',
    GET_USER_DETAILS_FAILED: 'getUserDetails/FAILED',
    GET_CURRENT_SERVER_TIME_REQUEST: 'getServerTime/REQUEST',
    GET_CURRENT_SERVER_TIME_RECEIVED: 'getServerTime/RECEIVED',
    GET_CURRENT_SERVER_TIME_FAILED: 'getServerTime/FAILED',

    //free bets
    GET_USER_FREE_BETS_REQUEST: 'getUserFreeBets/REQUEST',
    GET_USER_FREE_BETS_RECEIVED: 'getUserFreeBets/RECEIVED',
    GET_USER_FREE_BETS_FAILED: 'getUserFreeBets/FAILED',

    // balance
    GET_USER_BALANCE_REQUEST: 'getUserBalance/REQUEST',
    GET_USER_BALANCE_RECEIVED: 'getUserBalance/RECEIVED',
    GET_USER_BALANCE_FAILED: 'getUserBalance/FAILED',

    // --- Geocomply types --- //
    GEOCOMPLY_LICENSE_REQUEST: 'geoComplyLicense/REQUEST',
    GEOCOMPLY_LICENSE_RECEIVED: 'geoComplyLicense/RECEIVED',
    GEOCOMPLY_LICENSE_FAILED: 'geoComplyLicense/ERROR',

    GEOCOMPLY_CONNECT_REQUEST: 'geocomplyConnect/REQUEST',
    GEOCOMPLY_CONNECT_SUCCESS: 'geocomplyConnect/SUCCESS',
    GEOCOMPLY_CONNECT_FAILED: 'geocomplyConnect/FAILED',
    GEOCOMPLY_CONNECT_NOT_ALLOWED: 'geocomplyConnect/NOT_ALLOWED',

    GEOCOMPLY_SUBMIT_REQUEST: 'geoComplySubmit/REQUEST',
    GEOCOMPLY_SUBMIT_SUCCESS: 'geoComplySubmit/SUCCESS',
    GEOCOMPLY_SUBMIT_FAILED: 'geoComplySubmit/ERROR',

    // --- GAN types --- //
    GAN_SEAMLESS_LOGIN_REQUEST: 'GANSeamlessLogin/REQUEST',
    GAN_SEAMLESS_LOGIN_RECEIVED: 'GANSeamlessLogin/RECEIVED',
    GAN_SEAMLESS_LOGIN_FAILED: 'GANSeamlessLogin/FAILED',

    // --- GAN Validation types --- //
    GAN_VALIDATION_SUCCESS: 'GANValidation/SUCCESS',
    GAN_VALIDATION_FAILED: 'GANValidation/FAILED',

    // --- Game Launcher types --- //
    GAME_LAUNCHER_REQUEST: 'GameLauncher/REQUEST',
    GAME_LAUNCHER_RECEIVED: 'GameLauncher/RECEIVED',
    GAME_LAUNCHER_FAILED: 'GameLauncher/FAILED',

    // --- Game Launcher types --- //
    ERROR_CLOSE: 'Error/CLOSE',

    // reality check
    REALITY_CHECK_SUCCESS: 'realityCheck/RECEIVED',
    REALITY_CHECK_DISPLAY: 'realityCheck/DISPLAY',
    REALITY_CHECK_HIDE: 'realityCheck/HIDE',
    VERIFY_REALITY_CHECK: 'realityCheck/VERIFY',

    // --- Region Validation types --- //
    REGION_VALIDATION_SUCCESS: 'RegionValidation/SUCCESS',
    REGION_VALIDATION_FAILED: 'RegionValidation/FAILED',

    // Game Window
    GET_GAME_WINDOW_REQUEST: 'GameWindow/REQUEST',
    GET_GAME_WINDOW_RECEIVED: 'GameWindow/RECEIVED',
    GET_GAME_WINDOW_FAILED: 'GameWindow/FAILED',

    // --- Trending Games types --- //
    SEND_TRENDING_GAMES_REQUEST: 'SendTrendingGames/REQUEST',
    SEND_TRENDING_GAMES_SUCCESS: 'SendTrendingGames/SUCCESS',
    SEND_TRENDING_GAMES_FAILED: 'SendTrendingGames/FAILED',

    // --- Customer Segment types --- //
    CUSTOMER_SEGMENT_REQUEST: 'CustomerSegment/REQUEST',
    CUSTOMER_SEGMENT_RECEIVED: 'CustomerSegment/RECEIVED',
    CUSTOMER_SEGMENT_FAILED: 'CustomerSegment/FAILED',

    // --- User Context types --- //
    GET_USER_CONTEXT_REQUEST: 'GetUserContext/REQUEST',
    GET_USER_CONTEXT_RECEIVED: 'GetUserContext/RECEIVED',
    GET_USER_CONTEXT__FAILED: 'GetUserContext/FAILED',

    // --- Game Window -- //
    SET_IN_GAME_BALANCE: 'SET_IN_GAME_BALANCE',
    SET_AUDIO_ENABLED: 'SET_AUDIO_ENABLED',
    SET_STAKE: 'SET_STAKE',
    SET_WIN: 'SET_WIN',
    SET_SPIN_IN_PROGRESS: 'SET_SPIN_IN_PROGRESS',
    OPEN_MENU: 'OPEN_MENU',
    CLOSE_MENU: 'CLOSE_MENU',
    SET_AUDIO_SETTINGS_RECEIVED: 'SET_AUDIO_SETTINGS_RECEIVED',
    SET_IS_REWARDS_MACHINE: 'SET_IS_REWARDS_MACHINE',
    CLOSE_GAME_LAUNCHER: 'CLOSE_GAME_LAUNCHER',
    //-Exit Popup-//
    SHOW_EXIT_POPUP: 'SHOW_EXIT_POPUP',
    HIDE_EXIT_POPUP: 'HIDE_EXIT_POPUP',

    // Timers
    SET_SESSION_TIME: 'SET_SESSION_TIME',
    SET_CURRENT_TIME: 'SET_CURRENT_TIME',
    SET_GAME_TIME: 'SET_GAME_TIME',

    // License Card
    SET_LICENSE_CARD_VISIBLE: 'SET_LICENSE_CARD_VISIBLE',

    // --- Customer Data Package types --- //
    CUSTOMER_PACKAGE_REQUEST: 'CustomerPackage/REQUEST',
    CUSTOMER_PACKAGE_RECEIVED: 'CustomerPackage/RECEIVED',
    CUSTOMER_PACKAGE_FAILED: 'CustomerPackage/FAILED',

    // --- Customer Data Features types --- //
    CUSTOMER_FEATURES_REQUEST: 'CustomerFeatures/REQUEST',
    CUSTOMER_FEATURES_RECEIVED: 'CustomerFeatures/RECEIVED',
    CUSTOMER_FEATURES_FAILED: 'CustomerFeatures/FAILED',

    // --- Add Recently Played User Activity Features types --- //
    ADD_USER_ACTIVITY_REQUEST: 'ADD_USER_ACTIVITY/REQUEST',
    ADD_USER_ACTIVITY_RECEIVED: 'ADD_USER_ACTIVITY/RECEIVED',
    ADD_USER_ACTIVITY_FAILED: 'ADD_USER_ACTIVITY/FAILED',

    // --- Get Recently Played User Activity Features types --- //
    GET_USER_ACTIVITY_REQUEST: 'GET_USER_ACTIVITY/REQUEST',
    GET_USER_ACTIVITY_RECEIVED: 'GET_USER_ACTIVITY/RECEIVED',
    GET_USER_ACTIVITY_FAILED: 'GET_USER_ACTIVITY/FAILED',

    //-Exit Popup Recently Played-//
    SHOW_EXIT_POPUP_RECENTLY_PLAYED: 'SHOW_EXIT_POPUP_RECENTLY_PLAYED',
    HIDE_EXIT_POPUP_RECENTLY_PLAYED: 'HIDE_EXIT_POPUP_RECENTLY_PLAYED',
    SET_NEXT_GAME_URL: 'SET_NEXT_GAME_URL',
    SET_NEXT_GAME_ID: 'SET_NEXT_GAME_ID',

    // GW Generic error
    SET_IN_GAME_ERROR: 'SET_IN_GAME_ERROR',
    SET_DISMISS_IN_GAME_ERROR: 'SET_DISMISS_IN_GAME_ERROR',

    // GW Loaded
    GAME_LOAD_COMPLETE: 'GAME_LOAD_COMPLETE',
    GAME_WINDOW_EXPANSION_STATE: 'GAME_WINDOW_EXPANSION_STATE',
    GAME_EXPANSION_TOGGLE_DISPLAY_STATE: 'GAME_EXPANSION_TOGGLE_DISPLAY_STATE',
    GAME_WINDOW_EXPANSION_DISABLED: 'GAME_WINDOW_EXPANSION_DISABLED',

    SET_GAME_CONTEXT: 'SET_GAME_CONTEXT',
    SET_LOADING_PERCENT: 'SET_LOADING_PERCENT',
    SET_LOADING_SCREEN_VISIBILITY: 'SET_LOADING_SCREEN_VISIBILITY',

    // Amplitude Experiments
    SET_AMPLITUDE_EXPERIMENTS: 'SET_AMPLITUDE_EXPERIMENTS',
    SET_AMPLITUDE_EXPERIMENTS_LOADED: 'SET_AMPLITUDE_EXPERIMENTS_LOADED',
    SET_SWJ_ENABLED: 'SET_SWJ_ENABLED',

    // SWJ
    SET_SWJ_JACKPOT_DATA: 'SET_SWJ_JACKPOT_DATA',
    SET_SWJ_LATEST_TIERS: 'SET_SWJ_LATEST_TIERS',
    SET_SWJ_OPT_IN: 'SET_SWJ_OPT_IN',

    // Game client
    GAME_CLIENT_STATUS: 'SET_GAME_CLIENT_STATUS',
    GAME_CLIENT_HANDSHAKE: 'SET_GAME_CLIENT_HANDSHAKE',

    //FEATURES
    SET_FEATURE_FLAGS: 'SET_FEATURE_FLAGS'
};
