import { getUserData, getSession } from '../../unified-modules/account-library';

export const getSessionId = async () => {
    const session = await getSession();
    return session ? session.sessionId : null
};

export const getUserId = async () => {
    const userData = await getUserData();
    return userData ? userData.userId : null
};
