import { AmplKeys, USER_TRACKING_ALL_PROPS, GTMKeys } from './types';

export const AMPLITUDE_GA_KEYS_MAP = new Map([...Object.values(USER_TRACKING_ALL_PROPS)]);

export type AmplVal = {
    [key in AmplKeys]?: string | number;
};

export type GTMVal = {
    [key in GTMKeys]?: string | number;
};

const AMPLITUDE_GA_EVENT_NAME_MAP = new Map([
    ['In Game Menu Opened', 'in_game_menu_opened'],
    ['In Game Zone Viewed', 'in_game_zone_viewed'],
    ['In Game Navigation Link Clicked', 'navigation_link_clicked'],
    ['In Game CTA Clicked', 'cta_clicked'],
    ['In Game Exited', 'game_exit_clicked'],
    ['In Game Exit Modal Viewed', 'modal_viewed'],
    ['In Game Exit Modal Closed', 'modal_closed'],
    ['In Game Notification Viewed', 'in_game_notification_viewed'],
    ['In Game Notification Closed', 'in_game_notification_closed'],
    ['In Game Notification Selected', 'in_game_notification_clicked'],
    ['In Game Sound Adjusted', 'game_sound_adjusted'],
    ['In Game Error Modal Viewed', 'game_error_modal_viewed'],
    ['In Game Error CTA Clicked', 'game_error_cta_clicked'],
]);

export const amplitudeGtmMapper = (payload: AmplVal): GTMVal => {
    return Object.keys(payload).reduce((acc, key: AmplKeys) => {
        if (payload[key] && typeof payload[key] === 'object' && payload[key] !== null) {
            return { ...acc, [AMPLITUDE_GA_KEYS_MAP.get(key)]: amplitudeGtmMapper(payload[key] as AmplVal) };
        }

        if (key === 'Event') {
            return {
                ...acc,
                [AMPLITUDE_GA_KEYS_MAP.get(key)]:
                    AMPLITUDE_GA_EVENT_NAME_MAP.get(payload[key] as string) ?? payload[key]
            };
        }

        return {
            ...acc,
            [AMPLITUDE_GA_KEYS_MAP.get(key)]: payload[key]
        };
    }, {} as GTMVal);
};
