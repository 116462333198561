import { getRegion } from './cookies';
import config from '../../../config/config';

export const getFgpEndpoint = () => {
    const endpoint = config?.fgpEndpoint;

    if (!endpoint) {
        return null;
    }

    const region = getRegion()?.toLowerCase();

    return endpoint.replace('{state}', region);
}
