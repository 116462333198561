import { h } from 'preact';
import { useCallback, useEffect, useMemo } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { GAME_INFO_CARD_TYPES } from '../../../../common/constants';
import { openExternalWebview } from '../../../../common/helpers/open-external-webview';
import { useOrientation } from '../../../../common/hooks/useOrientation';
import { closeMenu } from '../../../../redux/actions/display-menu';
import { setLicenseCardVisible } from '../../../../redux/actions/game-window';
import { gameHelpItemsSelector, gameWindowSWJLatestTiers, gameWindowSWJOptIn } from '../../../../selectors/game-window';
import Card from './components/Card/Card';
import { useLogTracking } from '../../../../common/hooks/use-user-tracking/useUserTracking';
import { MODULES, ZONES, getAmplitudeKey } from '../../../../common/hooks/use-user-tracking/types';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../../redux/store';
import { getAmplitudeSWJTiersParameters } from '../../../../common/helpers/swjHelpers';
require(`./styles/${process.env.APPLICATION}/style.scss`);
interface GameHelpProps {
    toggleHelp: () => void;
    togglePaytable: () => void;
}

type CardTypes = keyof typeof GAME_INFO_CARD_TYPES;

export type CardActionMap = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key in typeof GAME_INFO_CARD_TYPES[CardTypes]]: (item?: any) => void;
};

const GameHelp = ({ toggleHelp, togglePaytable }: GameHelpProps) => {
    const { deviceType } = useOrientation();
    const { logTrackingComponent } = useLogTracking();

    const commonPayload = useMemo(() => ({
        [getAmplitudeKey('MODULE')]: MODULES.IN_GAME,
        [getAmplitudeKey('ZONE_NAME')]: ZONES.GAME_HELP
    }), []);

    useEffect(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'In Game Zone Viewed',
            ...commonPayload
        });
    }, [logTrackingComponent]);

    const dispatch = useDispatch<ThunkDispatch<RootState, void, AnyAction>>();
    const gameHelpItems = useSelector(gameHelpItemsSelector);
    const optIn = useSelector(gameWindowSWJOptIn);
    const jackpotTiers = useSelector(gameWindowSWJLatestTiers);

    const cardActionMapper: CardActionMap = useMemo(
        () => ({
            [GAME_INFO_CARD_TYPES.EXTERNAL_LINK]: item => {
                if (item?.card_title?.[0]?.text?.toLowerCase()?.includes('license')) {
                    dispatch(setLicenseCardVisible(true));
                }
            },
            [GAME_INFO_CARD_TYPES.HOW_TO_PLAY]: () => {
                toggleHelp();
                dispatch(closeMenu());
            },
            [GAME_INFO_CARD_TYPES.PAYTABLE]: () => {
                togglePaytable();
                dispatch(closeMenu());
            }
        }),
        [dispatch, toggleHelp, togglePaytable]
    );

    const handleCard = useCallback(
        item => {
            logTrackingComponent({
                [getAmplitudeKey('EVENT')]: 'In Game Navigation Link Clicked',
                [getAmplitudeKey('LINK_TEXT')]: item.card_title[0].text,
                [getAmplitudeKey('LINK_URL')]: item?.card_link?.url,
                [getAmplitudeKey('FDC_JACKPOT_OPT_IN_STATUS')]: optIn ? 'opted_in' : 'opted_out',
                ...getAmplitudeSWJTiersParameters(jackpotTiers),
                ...commonPayload
            });
            openExternalWebview(item?.card_link?.url);
        },
        [logTrackingComponent]
    );

    return (
        <div className={`game-help-container ${deviceType}`}>
            {gameHelpItems?.map((item, index) => {
                if (item.show_card) {
                    return (
                        <Card
                            title={item.card_title[0].text}
                            action={() => {
                                cardActionMapper?.[item.card_type](item);
                                handleCard(item);
                            }}
                            key={index}
                            deviceType={deviceType}
                            url={item?.card_link?.url}
                        />
                    );
                }
            })}
        </div>
    );
};

export default GameHelp;
