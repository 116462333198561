import jwt_decode from 'jwt-decode'

import awAxios from '../../../../common/axios/AWAxios'
import { addRetryInterceptor } from '../../../../common/axios/interceptors/retryInterceptor'
import { GEOCOMPLY_ERROR_TYPES } from '../../../../common/constants'
import { commonHeadersAW } from '../../../../common/commonHeaders'
import { FetchType, getRequestUid } from '../../../../common/helpers/fetch-helper'

import config from '../../../../../config/config'
import { fetchEnd, fetchError, fetchStart } from '../../../fetch/fetch'
import { locationVerified } from './location-verified'
import { verifyLocationFailed } from './verify-location-failed'
import { ddRumAction } from '../../../../common/helpers/datadog-wrapper'
import { ddActionVerifyLocationFailed } from '../../../../common/datadog/verify-location/vl-failed'
import { ddActionVerifyLocationInProgress } from '../../../../common/datadog/verify-location/vl-in-progress'
import { DD_USE_CASES } from '../../../../common/datadog/enums'

export const GEOCOMPLY_LOCATION_VERIFY = 'GEOCOMPLY_LOCATION_VERIFY'

addRetryInterceptor(awAxios)

// Reducer
export const reducer = state => {
    return {
        ...state,
        loading: true
    }
}

export const verifyLocation = (region, product, geoPacket) => async dispatch => {
    if (geoPacket) {
        const requestUid = getRequestUid(FetchType.CheckLocation)

        const body = { state: region, geopacket: geoPacket, product }

        try {
            console.warn('VERIFY LOCATION')
            dispatch(fetchStart(requestUid))
            dispatch({ type: GEOCOMPLY_LOCATION_VERIFY }) // could be removed i think

            const response = await awAxios.post(config.geoComply.endPoints.verify, body, {
                headers: commonHeadersAW
            })

            handleResponse(dispatch, response)
            dispatch(fetchEnd(requestUid))
        } catch (err) {
            dispatch(fetchError(requestUid))
            handleError(dispatch, err)
        }
    }
}

function handleResponse(dispatch, response) {
    console.info('submitGeoPacket()', response?.data)

    const responseToken = response?.data?.geolocation_check[0]

    const decodedToken = jwt_decode(responseToken.geolocation_token)

    ddRumAction(ddActionVerifyLocationInProgress({ response, decodedToken }, DD_USE_CASES.GLOBAL))

    if (decodedToken.result) {
        dispatch(locationVerified({ tokenDetails: decodedToken }))
    } else if (decodedToken.troubleshooter) {
        console.error('[VERIFY_LOCATION] verify location failed', JSON.stringify(decodedToken.error_message))
        ddRumAction(ddActionVerifyLocationFailed({ response, decodedToken }, DD_USE_CASES.GLOBAL))
        dispatch(
            verifyLocationFailed({
                type: GEOCOMPLY_ERROR_TYPES.VALIDATION,
                message: decodedToken.error_message,
                troubleshooter: decodedToken.troubleshooter
            })
        )
    }
}

function handleError(dispatch, error) {
    const status = error?.response?.status
    let errorMessage = ''
    switch (status) {
    case 403:
        errorMessage = 'User mismatch'
        break
    case 422:
        errorMessage = 'Location data not recognised'
        break
    default:
        errorMessage = JSON.stringify(error)
    }

    dispatch(verifyLocationFailed({ type: GEOCOMPLY_ERROR_TYPES.GENERAL, message: errorMessage }))
}
