import PlusIcon from '../../../../assets/icons/GameWindow/PlusIcon';
import { sendDepositMessage } from '../../../../common/helpers/send-deposit-message';
import { useCallback } from 'preact/hooks';
import { useSelector } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { MODULES, ZONES, getAmplitudeKey } from '../../../../common/hooks/use-user-tracking/types';
import { useLogTracking } from '../../../../common/hooks/use-user-tracking/useUserTracking';
import { gameWindowSWJLatestTiers, gameWindowSWJOptIn } from '../../../../selectors/game-window';
import { getAmplitudeSWJTiersParameters } from '../../../../common/helpers/swjHelpers';

interface AddFundsButtonProps {
    size: number;
    deviceType: string;
    depositLink: string;
    clickedLocation: 'header' | 'footer';
    sendDDEvent: boolean;
}

export const AddFundsButton = ({
    size,
    deviceType,
    depositLink,
    clickedLocation,
    sendDDEvent
}: AddFundsButtonProps) => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);

    const isSpinInProgress: boolean = useSelector((state: any): boolean => state?.gameWindow?.spinInProgress);

    const { logTrackingComponent } = useLogTracking();
    const jackpotTiers = useSelector(gameWindowSWJLatestTiers);
    const optIn = useSelector(gameWindowSWJOptIn);

    const handleDepositButton = useCallback(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'Navigation Link Clicked',
            [getAmplitudeKey('MODULE')]: clickedLocation === 'header' ? MODULES.IN_GAME_HEADER : MODULES.IN_GAME_FOOTER,
            [getAmplitudeKey('LINK_URL')]: depositLink,
            [getAmplitudeKey('LINK_TEXT')]: 'add_funds',
            [getAmplitudeKey('ZONE_NAME')]: clickedLocation === 'header' ? ZONES.DASHBOARD : ZONES.IN_GAME,
            [getAmplitudeKey('FDC_JACKPOT_OPT_IN_STATUS')]: optIn ? 'opted_in' : 'opted_out',
            ...getAmplitudeSWJTiersParameters(jackpotTiers)
        });
        sendDepositMessage();
        sendDDEvent && datadogRum.addAction(`CLICKED_ADD_FUNDS_${clickedLocation.toUpperCase()}`);
    }, [depositLink, sendDDEvent, clickedLocation, logTrackingComponent, jackpotTiers]);

    return (
        <a
            href={!isSpinInProgress && depositLink}
            rel="noreferrer"
            target="_blank"
            onClick={() => !isSpinInProgress && handleDepositButton()}
        >
            <div className="add-funds-container">
                {
                    <PlusIcon
                        isMobile={deviceType !== 'desktop'}
                        size={size}
                        className={`plus${isSpinInProgress ? ' disabled' : ''}`}
                    />
                }
            </div>
        </a>
    );
};
