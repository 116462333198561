import { types } from '../../types';

export const initialState = {
    displayModal: false,
    shouldVerifyRealityCheck: false,
    notificationParams: {
        interval: 15
    }
};

const realityCheckReducer = (state = initialState, action) => {
    switch (action.type) {
    case types.REALITY_CHECK_SUCCESS:
        return {
            ...state,
            notificationParams: action.payload
        };
    case types.REALITY_CHECK_DISPLAY:
        return {
            ...state,
            displayModal: true
        };
    case types.REALITY_CHECK_HIDE:
        return {
            ...state,
            displayModal: false
        };

    case types.VERIFY_REALITY_CHECK:
        return {
            ...state,
            shouldVerifyRealityCheck: action.payload
        };
    default: {
        return state;
    }
    }
};

export default realityCheckReducer;
