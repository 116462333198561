import { Brand } from '@fanduel/core-config-contract';
import { BRAND, COUNTRY, COUNTRY_ABBREVIATION, ENVIRONMENT } from '../../redux/types/unified-modules.types';
import config from '../../../config/config';

const qaEnvsUSA = ['qa1', 'qa2', 'fgp1', 'fgp2'];

export const getBrandUM = (brand: string): Brand => {
    if (brand === BRAND.MOHEGANSUN) {
        return Brand.MoheganSun;
    }
    return Brand.FanDuel;
};

export const getCountry = (country: string) => {
    if (country === COUNTRY.CANADA) {
        return COUNTRY_ABBREVIATION.CANADA;
    }
    return COUNTRY_ABBREVIATION.USA;
};

export const getEnvironment = () => {
    const env = config.env;

    if (env === ENVIRONMENT.PROD) {
        return ENVIRONMENT.PROD;
    }

    return process.env.COUNTRY === COUNTRY.CANADA || qaEnvsUSA.includes(env) ? ENVIRONMENT.QA : ENVIRONMENT.CERT;
};
