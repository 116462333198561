import config from '../../../../config/config'
import { substituteJsonPlaceholders } from '../../helpers/jsonPlaceholders'
import { useSelector } from 'react-redux'
import { datadogRum } from '@datadog/browser-rum'
import { isLoggedIn } from '../../helpers'
import { getSessionWithLoginView } from '../../../unified-modules/account-library'
import { getUserId } from '../../helpers/get-data-unified-modules'
import { isRunningInsideWrapper } from '../../wrapper-bridge-mobile'
import { getRegion } from '../../helpers/cookies'
import { getAccountLogger } from '../../../unified-modules/account-library-logger'

const useAuthenticated = async () => {
    const logger = getAccountLogger();

    let userId = useSelector(state => state?.session?.userId);
    const sessionId = useSelector(state => state?.session?.sessionId);
    const playerGuid = useSelector(state => state?.GANLogin?.ganToken?.playerGuid);
    const isAndroidWrapper = isRunningInsideWrapper();

    try {
        const loggedIn = await isLoggedIn();
        logger.debug(`Checking login status: ${loggedIn}`);

        if (loggedIn) {
            // TODO user===true what is this, refactor this
            if (!userId || userId === true) {
                userId = await getUserId();
            }

            sessionStorage.setItem('hadActiveSession', 'true');
            datadogRum.setUser({
                id: userId, // externalPlayerRef
                sessionId: sessionId || 'undefined',
                playerGuid: playerGuid || 'undefined'
            });
            return;
        }

        const session = await getSessionWithLoginView();
        if (session && !isAndroidWrapper) {
            sessionStorage.setItem('hadActiveSession', 'true');
            window.location.reload();
            return;
        }

        if (isAndroidWrapper){
            const variables = {
                region: getRegion()?.toUpperCase(),
                externalReferrerNext: encodeURIComponent(window.location.search)
            };

            const loginURL = substituteJsonPlaceholders(config['loginURL'], variables);
            window.location = loginURL;
            return;
        }

    } catch (error) {
        logger.error('Error in useAuthenticated:', error);
    }
};

export default useAuthenticated;

