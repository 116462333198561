import { Account } from '@fanduel/account';
import { setAWCookies } from '../common/helpers/cookies';
import { initializeUnifiedModules } from './unified-modules';
import { clearSessionAndRedirectToLogin } from '../common/helpers/clearSessionAndRedirectToLogin';

export const initializeAccountUM = async () => {
    await Account.initialize();
};

export const checkValidSession = async () => {
    await Account.checkValidSession();
}

let initialize: any = null;
let isInitializingPromise: Promise<any> | null = null;

export const syncCookiesUM = async session => {
    const authToken = session.token;
    const loginToken = await getLoginToken();

    setAWCookies(authToken, loginToken);
};

export const initializeUM = async () => {
    if (initialize) {
        return Promise.resolve(initialize)
    }

    if (isInitializingPromise) {
        await isInitializingPromise;
        return initialize;
    }

    isInitializingPromise = (async () => {
        try {
            initialize = await initializeUnifiedModules();
            return initialize;
        } finally {
            isInitializingPromise = null;
        }
    })();

    return isInitializingPromise;
};

export const subscribeSession = async () => {
    return Account.subscribeSession(session => {
        const hadActiveSession = !!sessionStorage.getItem('hadActiveSession');

        if (!session && hadActiveSession) {
            sessionStorage.removeItem('hadActiveSession');
            clearSessionAndRedirectToLogin();
        }

        if (session && !hadActiveSession) {
            sessionStorage.setItem('hadActiveSession', 'true');
            syncCookiesUM(session);
        }
    });
};

export const getSession = () => {
    return Account.getSessionAsync('Silent');
}

export const getSessionWithLoginView = async () => {
    await initializeUM();
    return Account.getSessionAsync('Normal');
}

export const isCurrentLocalSessionValid = async () => {
    await initializeUM();
    return Account.getSessionAsync('Local');
};

/**
 * @deprecated
 * This should be removed when we don't use login-token
 */
export const getLoginToken = async () => {
    await initializeUM();
    return Account.getLoginTokenAsync();
};

export const getUserData = async () => {
    await initializeUM();
    return Account.getUserAsync('Silent');
};

export const clearSession = () => {
    return Account.clearSession();
};

export const keepAlive = () => {
    return Account.keepAlive();
};

export const getPriorSession = () => {
    return Account.getPriorSession();
};
