import { useEffect, useState, useLayoutEffect, useCallback } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { locationVerified } from '../../../redux/geo-comply/location/actions/location-verified';
import { getGeocomplyIpCookie, getWrapperCookies } from '../../helpers/cookies';
import { getUserId, getSessionId } from '../../helpers/get-data-unified-modules';
import { experimentsSelector } from '../../../selectors/amplitude-experiments';

const useGeolocationVerifyXsell = () => {
    const dispatch = useDispatch();
    const geoPacketValidated = useSelector(state => state?.plc?.validated);
    const [geocomplyIpAddress, setGeocomplyIpAddress] = useState(getGeocomplyIpCookie());
    const [sessionId, setSessionId] = useState(null);
    const [userId, setUserId] = useState(null);
    const { isNewQuickLaunchFlow } = getWrapperCookies();
    const amplitudeExperiments = useSelector(experimentsSelector);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const session = await getSessionId();
                const user = await getUserId();

                setSessionId(Number(session));
                setUserId(user);

            } catch (error) {
                console.error("Error fetching session or user data", error);
            }
        };

        fetchData();

        const interval = setInterval(() => {
            try {
                setGeocomplyIpAddress(getGeocomplyIpCookie());
            } catch (error) {
                console.error(error);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const handleGeoValidationDone = useCallback(() => {
        if (!sessionId || !userId ) {
            return;
        }

        dispatch(
            locationVerified({
                tokenDetails: {
                    result: true,
                    verified: true,
                    ip_address: geocomplyIpAddress || '10.123.0.55', // TODO: remove hardcoded IP
                    session_id: sessionId,
                    user_id: userId,
                },
                geocomplyEnabled: amplitudeExperiments?.geocomplyEnabled === 'on'
            })
        );
    }, [dispatch, sessionId, userId, geocomplyIpAddress]);

    useEffect(() => {
        if (sessionId && userId) {
            handleGeoValidationDone();
        }
    }, [sessionId, userId, geocomplyIpAddress, handleGeoValidationDone]);

    useLayoutEffect(() => {
        if (!geoPacketValidated) {
            if (isNewQuickLaunchFlow === 'true') {
                window.addEventListener('sportsbookGeoValidationDone', handleGeoValidationDone);
            } else {
                handleGeoValidationDone();
            }
        }

        return () => window.removeEventListener('sportsbookGeoValidationDone', handleGeoValidationDone);
    }, [handleGeoValidationDone, isNewQuickLaunchFlow, geoPacketValidated]);
};

export default useGeolocationVerifyXsell;
