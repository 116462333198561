import { h } from 'preact';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

require(`./styles/${process.env.APPLICATION}/style.scss`);

import AppleLogo from '../../assets/icons/AppleLogo/AppleLogo';
import WindowsLogo from '../../assets/icons/WindowsLogo/WindowsLogo';

import config from '../../../config/config';

import { getUserOS } from '../../common/helpers/platform';
import { generateDownloadUrl } from '../../common/helpers/plc-helper';

const DownloadPluginSteps = () => {
    const { t } = useTranslation();
    const os = getUserOS();
    const history = useHistory();

    const userId = useSelector(state => state?.session?.userId);
    const isProductionEnv = config.env === 'prod';

    const downloadPluginUrl = generateDownloadUrl(
        userId,
        config.geoComply?.plc?.installerKey,
        config.geoComply?.plc?.version,
        os,
        isProductionEnv
    );

    const logo = os === 'mac' ? <AppleLogo /> : <WindowsLogo />;
    const goBack = () => {
        history.goBack();
    };

    return (
        <div className="download-plugin-steps">
            {logo}
            <ol>
                <li>
                    <a href={downloadPluginUrl} className="btn btn__link btn-main">
                        {t('downloadPluginSteps.download')}
                    </a>
                </li>
                <li>
                    <h5 className="subtitle">{t('downloadPluginSteps.install')}</h5>
                    <p>{t('downloadPluginSteps.pluginHasDownloaded')}</p>
                </li>
                <li>
                    <h5 className="subtitle">{t('global.play')}</h5>
                    <p>{t('downloadPluginSteps.pluginSuccessfullyInstalled')}</p>
                </li>
                <li>
                    <a nohref onClick={() => goBack()} className="btn btn__link btn-main">
                        {t('global.play')}
                    </a>
                </li>
            </ol>
        </div>
    );
};

export default DownloadPluginSteps;
