import { types } from '../../../types';
import { FetchType, getRequestUid } from '../../../../common/helpers/fetch-helper';
import { fetchEnd } from '../../../fetch/fetch';
import { getSessionId, getUserId } from '../../../../common/helpers/get-data-unified-modules';

export const getUserDetailsXsell = () => async (dispatch) => {
    const requestUid = getRequestUid(FetchType.SeamlessLogin);

    //todo refactor and get details from wrapper. It works without, but are useful for datadog
    const sessionId = await getSessionId();
    const userId = await getUserId();

    dispatch({
        type: types.GET_USER_DETAILS_RECEIVED,
        payload: { sessionId, userId }
    });
    dispatch(fetchEnd(requestUid));
};

export default getUserDetailsXsell;
