import { isRunningInsideWrapper, sendWrapperEvent } from '../wrapper-bridge-mobile';
import { MESSAGES } from '../constants';
import { PLATFORM } from '../constants/game-constants';
import { getAppPlatform } from './app-platform';

export const sendDepositMessage = () => {
    if (getAppPlatform() === PLATFORM.Xsell && isRunningInsideWrapper()) {
        sendWrapperEvent(MESSAGES.SHOW_DEPOSIT, '');
    }
};
