import { useCallback, useEffect, useLayoutEffect } from 'preact/hooks';
import { useGeocomplyCallback } from '../../../../common/hooks/use-geocomply-flow/use-geocomply-callback';
import { useDispatch, useSelector } from 'react-redux';
import { isRunningInsideWrapper, sendWrapperEvent } from '../../../../common/wrapper-bridge-mobile';
import { MESSAGES } from '../../../../common/constants';
import { dismissLauncher } from '../../../../common/helpers/redirect-flows';
import { showPopup } from '../../../../redux/reducers/popup/popup';
import { POPUP_DISMISS_ACTIONS } from '../../../../redux/reducers/popup/types';
import { handleLoadingScreen } from '../../LoadingScreen/helpers/loadingScreenHandler';
import { datadogRum } from '@datadog/browser-rum';
import connectionType from '../../../../common/helpers/connectionType';
import { experimentsSelector } from '../../../../selectors/amplitude-experiments';
import { gameClientErrorsHandler } from '../helpers/gameClientErrorsHandler';
import { GAME_CLIENT_ERRORS } from '../helpers/types';
import { PLATFORM } from '../../../../common/constants/game-constants';

export const useGameClientMessageHandler = (provider?: string) => {
    const dispatch = useDispatch();
    const { handleGeolocationFailure, handleGeolocationPassed, handleGeolocationRejected } = useGeocomplyCallback();
    const amplitudeExperiments = useSelector(experimentsSelector);

    const wrapperResponseHandler = (e: CustomEvent) => {
        const gameClientFrame = document.querySelector<HTMLIFrameElement>('#casino-game-client');
        gameClientFrame.contentWindow.postMessage(
            {
                type: 'GL/GEOLOCATION_RESULT',
                payload: e.detail
            },
            '*'
        );
    };

    useLayoutEffect(() => {
        window.addEventListener('geolocationResult', wrapperResponseHandler);

        return () => window.removeEventListener('geolocationResult', wrapperResponseHandler);
    }, []);

    const wrapperMessageEventHandler = event => {
        if (isRunningInsideWrapper() && event?.data?.type === MESSAGES.REQUEST_GAME_CLOSE) {
            dismissLauncher();
        }
    };

    useEffect(() => {
        window.addEventListener('message', wrapperMessageEventHandler);
        return () => {
            window.removeEventListener('message', wrapperMessageEventHandler);
        };
    }, [dispatch]);

    const eventHandler = useCallback(
        (e: MessageEvent) => {
            const modifiedEventPayload = {
                ...e?.data?.payload?.payload,
                geocomplyEnabled: amplitudeExperiments?.geocomplyEnabled === 'on'
            };

            /*
                For iOS web launcher catch the INITIALIZE event and forward the entire response to client. 
                We will deny the launcher to personally send the event to prevent wrong/ double initialization.
                For the normal launcher flow (WEB/ANDROID) we will send this event from launcher itself. 
            */
            if (e?.data?.type === 'GL/INITIALIZE_HANDSHAKE_PARAMS' && e?.data?.source === PLATFORM.Ios) {
                const gameClientFrame = document.querySelector<HTMLIFrameElement>('#casino-game-client');
                gameClientFrame?.contentWindow.postMessage(
                    {
                        ...e?.data
                    },
                    '*'
                );
            }

            if (e.data?.type?.startsWith('GC/')) {
                switch (e.data.type) {
                case 'GC/GEOLOCATION_SUCCESS':
                    handleGeolocationPassed(modifiedEventPayload);
                    handleLoadingScreen(dispatch, provider?.toLocaleUpperCase());
                    break;
                case 'GC/GEOLOCATION_FAILURE':
                    handleGeolocationFailure(modifiedEventPayload);
                    break;
                case 'GC/GEOLOCATION_REJECTED':
                    handleGeolocationRejected(modifiedEventPayload);
                    break;
                case 'GC/FETCH_GAME_FAILURE':
                    gameClientErrorsHandler(
                        dispatch,
                        GAME_CLIENT_ERRORS[e.data?.payload?.errorCode] ?? GAME_CLIENT_ERRORS.GENERIC_ERROR
                    );
                    break;
                case 'GC/CONTEXT_PARAM_MISSING':
                    dispatch(
                        showPopup({
                            title: 'Invalid input provided',
                            icon: true,
                            description: `The following parameters are missing from the url: ${e.data.payload}`,
                            buttons: {
                                dismissAction: {
                                    title: 'Dismiss',
                                    handler: POPUP_DISMISS_ACTIONS.DISMISS_POPUP
                                }
                            }
                        })
                    );
                    break;
                case 'GC/UPDATE_GEOCOMPLY_FAILURE':
                    dispatch(
                        showPopup({
                            title: 'Geolocation error',
                            icon: true,
                            description: "Your location couldn't be verified",
                            buttons: {
                                dismissAction: {
                                    title: 'Dismiss',
                                    handler: POPUP_DISMISS_ACTIONS.DISMISS_POPUP
                                }
                            }
                        })
                    );
                    break;
                }
            }

            // IGT free spins transfer to cash game
            try {
                if (e?.data && typeof e.data === 'string' && e.data === 'reloadGame') {
                    isRunningInsideWrapper() ? sendWrapperEvent(MESSAGES.RELOAD_GAME, '') : window.location.reload();
                }
            } catch (error) {
                datadogRum.addError(
                    new Error(error),
                    Object.assign(
                        {
                            type: 'RELOAD_GAME_FAILED',
                            provider: 'IGT',
                            connectionType: connectionType()
                        },
                        error
                    )
                );
            }
        },
        [dispatch, handleGeolocationFailure, handleGeolocationPassed, handleGeolocationRejected, provider]
    );

    useEffect(() => {
        window.addEventListener('message', eventHandler);

        return () => window.removeEventListener('message', eventHandler);
    }, [eventHandler]);
};
