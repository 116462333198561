import { types } from '../../types';
import { datadogRum } from '@datadog/browser-rum';
import { fetchEnd, fetchError, fetchStart } from '../../fetch/fetch';
import connectionType from '../../../common/helpers/connectionType';
import { FetchType, getRequestUid } from '../../../common/helpers/fetch-helper';
import { FabricServicesAxios, getFabricEndpoint, getFabricURL } from '../../../common/axios/FabricServicesAxios';
import { AGGREGATION_HEADERS, MESSAGES, RECENTLY_GAMES_OPERATION } from '../../../common/constants';
import { getRegion } from '../../../common/helpers/cookies';
import { getBrand } from '../../../common/helpers/brand';
import { isRunningInsideWrapper, sendWrapperEvent } from '../../../common/wrapper-bridge-mobile';
import config from '../../../../config/config';

const refreshLobbyRecentlyPlayedZone = () => {
    if (isRunningInsideWrapper()) {
        sendWrapperEvent(MESSAGES.REFRESH_RECENTLY_PLAYED, '');
    } else {
        window?.opener?.postMessage?.({ type: MESSAGES.REFRESH_RECENTLY_PLAYED }, config.casinoWebUrl);
    }
};

export const addRecentlyPlayedOnGameInit = (gameId, deviceType) => async dispatch => {
    const requestUid = getRequestUid(FetchType.AddRecentlyPlayed);

    try {
        const recentlyGamesEndpoint = getFabricEndpoint('userActivity', 'recentlyPlayedGames');
        const operation = RECENTLY_GAMES_OPERATION.ADD;
        const fabricURL = getFabricURL();
        const baseURL = process.env.NODE_CONFIG_ENV === 'local-development' ? `${fabricURL}:4000` : fabricURL;
        const product = process.env.PRODUCT;

        const region = getRegion();
        const brand = getBrand();

        const platform = deviceType === 'desktop' ? 'desktop' : 'mobile';

        const apiUrl = `${recentlyGamesEndpoint}/${operation}?gameCode=${gameId}&product=${product}&gameIdentifierType=id&platform=${platform}`;

        dispatch(fetchStart(requestUid));
        dispatch({ type: types.ADD_USER_ACTIVITY_REQUEST });

        const response = await FabricServicesAxios().get(apiUrl, {
            baseURL,
            redirect: true,
            headers: {
                [AGGREGATION_HEADERS.X_BRAND]: brand,
                [AGGREGATION_HEADERS.X_REGION_CODE]: region
            },
            withCredentials: true
        });

        const data = response?.data;

        dispatch({
            type: types.ADD_USER_ACTIVITY_RECEIVED,
            payload: data
        });
        dispatch(fetchEnd(requestUid));

        datadogRum.addAction('ADD_RECENTLY_PLAYED_SUCCESS');

        refreshLobbyRecentlyPlayedZone();
    } catch (error) {
        dispatch(fetchError(requestUid));
        dispatch({
            type: types.ADD_USER_ACTIVITY_FAILED,
            payload: error?.name
        });

        datadogRum.addError(
            new Error(error),
            Object.assign(
                {
                    type: 'ADD_RECENTLY_PLAYED_FAILED',
                    connectionType: connectionType()
                },
                error
            )
        );
    }
};

export default addRecentlyPlayedOnGameInit;
