import { removeAWCookies } from './cookies'
import { isCurrentLocalSessionValid } from '../../unified-modules/account-library'

const isLoggedIn = async () => {
    try {
        const validSession = await isCurrentLocalSessionValid()

        if (validSession){
            return true
        }

        removeAWCookies()
        return false

    } catch (error){
        // when checkValidSession throws return false
        return false
    }
}

export default isLoggedIn
