import { types } from '../../types'

export const initialState = {
    userActivityData: null,
    loading: false,
    error: null
}

const addRecentlyPlayedReducer = (state = initialState, action) => {
    switch (action.type) {
    case types.ADD_USER_ACTIVITY_REQUEST:
        return {
            ...state,
            loading: true,
        }
    case types.ADD_USER_ACTIVITY_RECEIVED:
        return {
            ...state,
            userActivityData: action.payload,
            loading: false,
            error: false
        }
    case types.ADD_USER_ACTIVITY_FAILED:
        return {
            ...state,
            loading: false,
            error: action.payload
        }
    default: {
        return state
    }
    }
}

export default addRecentlyPlayedReducer
