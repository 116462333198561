import { reducer as geoComplyReducer } from '../geo-comply/geo-comply';
import { reducer as geoPLCReducer } from '../geo-plc/geo-plc';
import { reducer as fetchReducer } from '../fetch/fetch';

// import { geoComplyLicense, geoComplyConnect, geoComplySubmit } from './pl'
import { balance, session, freeBets } from './accountAndWallet';
import { realityCheck } from './reality-check';
import { regionValidator } from './region';
import { gameWindow } from './game-window';
import { userContextReducer } from './user-context';
import exitPopup from './exit-popup/exit-popup';
import displayMenu from './display-menu/display-menu';
import { customerData } from './customer-data';
import { recentlyPlayedReducer } from './get-user-activity';
import { addRecentlyPlayedReducer } from './add-user-activity';
import exitPopupRecentlyPlayed from './exit-popup-recently-played/exit-popup-recently-played';
import featureFlags from './featureFlags/feature-flags';
import { popup } from './popup/index';
import { gameContextReducer } from './gameContext/gameContext';
import { amplitudeExperimentsReducer } from './amplitude-experiments';
import { SiteWideJackpotReducer } from './site-wide-jackpot';
import { gameGameClientStatusReducer } from './gameClientStatus';

export const rootReducer = {
    geocomply: geoComplyReducer,
    plc: geoPLCReducer,
    fetch: fetchReducer,
    session,
    balance,
    freeBets,
    regionValidator,
    realityCheck,
    gameWindow,
    exitPopup,
    displayMenu,
    customerData,
    userContextReducer,
    recentlyPlayedReducer,
    addRecentlyPlayedReducer,
    exitPopupRecentlyPlayed,
    featureFlags,
    popup,
    amplitudeExperiments: amplitudeExperimentsReducer,
    gameContext: gameContextReducer,
    siteWideJackpot: SiteWideJackpotReducer,
    gameClientStatus: gameGameClientStatusReducer
};

export default rootReducer;
