import { types } from '../../../types';
import { FetchType, getRequestUid } from '../../../../common/helpers/fetch-helper';
import { fetchEnd, fetchError, fetchStart } from '../../../fetch/fetch';
import config from '../../../../../config/config';
import { MESSAGES } from '../../../../common/constants'
import { getUserData , getSession } from '../../../../unified-modules/account-library';

let hasTriggeredReload = false;

export const getCurrentSessionDetailsXsell = () => async dispatch => {
    const requestUid = getRequestUid(FetchType.UserSession);

    dispatch(fetchStart(requestUid));
    dispatch({ type: types.GET_CURRENT_SESSION_DETAILS_REQUEST });

    try {
        const userData = await getUserData();
        const sessionUM = await getSession();

        const sessionStartTime = sessionUM.created
        const mfa = userData.mfa

        dispatch({
            type: types.GET_CURRENT_SESSION_DETAILS_RECEIVED,
            payload: { sessionStartTime, mfa }
        });
        dispatch(fetchEnd(requestUid));

        //todo since only the following part is different than android, we could further granulate and handle only the response differently
        if (!hasTriggeredReload) {
            window?.opener?.postMessage?.({ type: MESSAGES.RELOAD }, config.casinoWebUrl);
            hasTriggeredReload = true;
        }

    } catch (error) {
        dispatch({
            type: types.GET_CURRENT_SESSION_DETAILS_FAILED,
            payload: { name: error }
        });
        dispatch(fetchError(requestUid));
    }
};

export default getCurrentSessionDetailsXsell;
