import { sendWrapperEvent } from '../../../common/wrapper-bridge-xsell';
import { XSELL_EVENTS_PREFIX, allowedXSellAlchemerEvents, ALCHEMER_EVENT } from './constants';

const sendToAlchemerXsell = (event, data = {}) => {
    const eventWithXsellPrefix = `${XSELL_EVENTS_PREFIX} ${event}`;

    if (allowedXSellAlchemerEvents.includes(eventWithXsellPrefix)) {
        try {
            sendWrapperEvent(
                ALCHEMER_EVENT,
                JSON.stringify({
                    data_type: eventWithXsellPrefix,
                    data
                })
            );
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Error sending event to Alchemer', err);
            return false;
        }
    }
};

export default sendToAlchemerXsell;
