import MaintenanceIllustration from '../../../../assets/icons/Maintenance/MaintenanceIllustration';

const Maintenance = ({ exitGame }) => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);

    return (
        <div className="maintenance-container">
            <span className="maintenance-icon-container" />
            <h1 className="maintenance-h1">Maintenance Page for Casino</h1>
            <p className="maintenance-paragraph">Sorry, we're working on a fix. Please go back home and try again later.</p>
            <p className="maintenance-paragraph"> Thanks for your patience.</p>
            <button className="maintenance-button" onClick={exitGame}> Go to lobby</button>
            <MaintenanceIllustration />
        </div>
    );
}

export default Maintenance;
