import { h, Fragment } from 'preact';
import { useContext, useEffect, useState, useRef, useCallback, useMemo } from 'preact/hooks';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';

import ExitButton from '../../ExitButton/ExitButton';
import LogoIcon from '../../../../../assets/icons/GameWindow/LogoIcon';
import Timers from '../../../../Timers/Timers';
import MenuTitle from './MenuTitle';
import { SoundToggle, WalletBalance } from '../../../components';
import { MenuContent } from './MenuContent';

import {
    showExitButtonSelector,
    showLogoSelector,
    showResponsibleGamingSelector,
    showSoundButtonSelector
} from '../../../../../selectors/game-window';
import { menuItemsSelector, dashboardItemsSelector } from '../../../../../selectors/game-window/content';
import { getIconSize } from '../../../../../common/helpers/get-icon-size';
import { MenuContentWrapper } from './MenuContentWrapper';
import { ResponsibleGaming } from './ResponsibleGaming';
import { GameWindowContext } from '../../../GameWindow';
import { useLogTracking } from '../../../../../common/hooks/use-user-tracking/useUserTracking';
import { MODULES, ZONES, getAmplitudeKey } from '../../../../../common/hooks/use-user-tracking/types';
import { dashboardSlides } from './../constants';
import { getRecentlyPlayed } from './../../../../../redux/actions/recently-played/get-recently-played';

// eslint-disable-next-line sonarjs/cognitive-complexity
const MenuToggle = () => {
    const [menuItem, setMenuItem] = useState('');
    const [dashboardCardWidth, setDashboardCardWidth] = useState('');
    const { logTrackingComponent } = useLogTracking();
    const dispatch = useDispatch();

    const { toggleAudio, deviceType, isPortrait } = useContext(GameWindowContext);

    const menuItems = useSelector(menuItemsSelector);
    const menuContentRef = useRef(null);
    const showSoundButton = useSelector(showSoundButtonSelector);
    const showLogo = useSelector(showLogoSelector);
    const showExitButton = useSelector(showExitButtonSelector);
    const showResponsibleGaming = useSelector(showResponsibleGamingSelector);
    const dashboardItems = useSelector(dashboardItemsSelector);

    const { RECENTLY_PLAYED } = dashboardSlides;
    const recentlyPlayedItems = useSelector(state => state?.recentlyPlayedReducer?.userActivityData);

    const isRecentlyEnabled = useMemo(() => {
        const recently = dashboardItems?.find(item => item?.type === RECENTLY_PLAYED);

        return recently && recently?.show;
    }, [dashboardItems, RECENTLY_PLAYED]);

    useEffect(() => {
        if (isRecentlyEnabled && !recentlyPlayedItems) {
            dispatch(getRecentlyPlayed(deviceType));
        }
    }, [deviceType, dispatch, isRecentlyEnabled, recentlyPlayedItems]);

    useEffect(() => {
        setDashboardCardWidth(menuContentRef.current?.clientWidth);
    }, [deviceType]);

    const wallet = <WalletBalance deviceType={deviceType} />;
    const sound = showSoundButton ? (
        <SoundToggle
            {...{
                toggleAudio,
                width: deviceType === 'desktop' ? '30' : '27',
                height: deviceType === 'desktop' ? '22' : '20'
            }}
        />
    ) : (
        <Fragment />
    );

    const sendGtmMessage = useCallback(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'In Game Exited',
            [getAmplitudeKey('MODULE')]: MODULES.IN_GAME,
            [getAmplitudeKey('ZONE_NAME')]: ZONES.DASHBOARD
        });
    }, [logTrackingComponent]);

    const exitButton = showExitButton ? <ExitButton direction={'row'} size={'17'} onClick={sendGtmMessage} zoneName={ZONES.DASHBOARD} /> : <Fragment />;
    const verticalLine = showExitButton && showSoundButton ? <div className="vertical-divider" /> : <Fragment />;
    const responsibleGambling = showResponsibleGaming && <ResponsibleGaming deviceType={deviceType} />;

    return (
        <motion.div
            className={`menu-wrapper ${deviceType === 'desktop' ? '' : deviceType}`}
            key="menu"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 20, opacity: 0 }}
            transition={{ duration: 0.3 }}
        >
            {deviceType !== 'portrait' && (
                <div className={'menu-header'}>
                    {deviceType === 'landscape' && (
                        <MenuTitle
                            title={menuItem.title}
                            icon={menuItem.icon}
                            isTitle={true}
                            size={getIconSize(deviceType, true)}
                        />
                    )}
                    {wallet}
                    {sound}
                    {verticalLine}
                    {exitButton}
                </div>
            )}
            <div className={'menu-content'}>
                {deviceType === 'desktop' && <div className="card-overlay" style={{ pointerEvents: 'none' }} />}
                {deviceType !== 'landscape' && (
                    <MenuTitle
                        title={menuItem.title}
                        icon={menuItem.icon}
                        isTitle={true}
                        size={getIconSize(deviceType, true)}
                    />
                )}
                <div className={'menu-content-content'} ref={menuContentRef}>
                    <MenuContentWrapper
                        // carouselHeight={carouselHeight} TODO: TBD if needed with carousel
                        className={`dashboard-wrapper ${menuItem?.icon === 'game_help' ? 'game-help-zone' : ''}`}
                    >
                        {menuItem.content}
                    </MenuContentWrapper>
                </div>

                <div className={'menu-content-nav'}>
                    <MenuContent
                        activeItem={menuItem}
                        dashboardCardWidth={dashboardCardWidth}
                        menuItems={menuItems}
                        setActive={setMenuItem}
                    />
                </div>
            </div>
            <div className={'menu-footer'}>
                {isPortrait && <Timers isPortrait={isPortrait} menu={true} isLandscape={deviceType === 'landscape'} />}
                <div className={'menu-footer-right'}>
                    {responsibleGambling}
                    {showLogo ? <LogoIcon deviceType={deviceType} /> : <Fragment />}
                </div>
            </div>
        </motion.div>
    );
};

export default MenuToggle;
