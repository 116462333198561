export const PRODUCT = {
    CASINO: 'casino'
} as const;

export const UNIFIED_MODULES_BRANDS = {
    MOHEGANSUN: 'MoheganSun',
    FANDUEL: 'FanDuel'
} as const;

export const BRAND = {
    FANDUEL: 'fanduel',
    MOHEGANSUN: 'mohegansun'
} as const;

export const ENVIRONMENT = {
    CERT: 'cert',
    PROD: 'prod',
    QA: 'qa'
} as const;

export const COUNTRY_ABBREVIATION = {
    USA: 'us',
    CANADA: 'ca',
} as const;

export const COUNTRY = {
    USA: 'usa',
    CANADA: 'canada',
} as const;


export type UnifiedModulesBrands = typeof UNIFIED_MODULES_BRANDS[keyof typeof UNIFIED_MODULES_BRANDS];
export type Product = typeof PRODUCT[keyof typeof PRODUCT];
export type Environment = typeof ENVIRONMENT[keyof typeof ENVIRONMENT];
