import { types } from '../../types';
import { FetchType, getRequestUid } from '../../../common/helpers/fetch-helper';
import { fetchEnd, fetchError, fetchStart } from '../../fetch/fetch';
import { FabricServicesAxios, getFabricURL, getFabricEndpoint } from '../../../common/axios/FabricServicesAxios';
import { getDomain } from '../../../common/helpers/cookies';
import { getUserId } from '../../../common/helpers/get-data-unified-modules';
import { getTrendingGamesChannel } from '../../../common/helpers/platform';
import { ddRumAction } from '../../../common/helpers/datadog-wrapper';
import { ddActionSendTrendingGames } from '../../../common/datadog/common/send-trending-games';
import { DD_USE_CASES } from '../../../common/datadog/enums';
import { gameIdSelector, regionSelector } from '../../../selectors/gameContext';
import { getBrand } from '../../../common/helpers/brand';

export const sendTrendingGames = () => async (dispatch, getState) => {
    const requestUid = getRequestUid(FetchType.TrendingGames);
    const endpoint = getFabricEndpoint('trendingGames', 'addGame');
    const baseURL = getFabricURL();
    const accId = await getUserId();
    const gameId = gameIdSelector(getState());
    const channel = getTrendingGamesChannel();
    const product = process.env.PRODUCT;
    const domain = getDomain();
    const region = regionSelector(getState());
    const brand = getBrand();

    try {
        if (accId && gameId && channel && product && region && brand) {
            dispatch(fetchStart(requestUid));
            dispatch({ type: types.SEND_TRENDING_GAMES_REQUEST });

            const response = await FabricServicesAxios().get(endpoint, {
                baseURL,
                params: {
                    accId,
                    gameId,
                    channel,
                    product
                },
                headers: {
                    'x-base-domain': domain,
                    'x-region-code': region,
                    'x-brand': brand
                }
            });

            const data = response?.data;

            ddRumAction(ddActionSendTrendingGames(data, DD_USE_CASES.GAME_LAUNCHER));

            dispatch({
                type: types.SEND_TRENDING_GAMES_SUCCESS
            });

            dispatch(fetchEnd(requestUid));
        }
    } catch (error) {
        dispatch({
            type: types.SEND_TRENDING_GAMES_FAILED
        });
        dispatch(fetchError(requestUid));
    }
};

export default sendTrendingGames;
