import { MDS } from "@fanduel/casino-swj-consumer";
import { useEffect, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import "./styles/default.scss";

import { swjTiersSelector, swjEnabledSelector, swjFeatureFlagSelector } from "../../../selectors/site-wide-jackpot";
import { getMDSEndpoint } from "../../../common/helpers/get-mds-endpoint";
import { isGameWindowDisabled, isIosWrapper, sendIosTokenRefreshMessage } from '../../../common/wrapper-bridge-mobile';
import { ERROR_TYPE } from "../../../game-window/contants/errors";
import { setInGameError } from "../../../redux/actions/game-window";
import { getAWCookies } from '../../../common/helpers/cookies';

const defaultValue = {
    userId: "163694",
    messageId: "messageid_1",
    type: "JACKPOT",
    subtype: "WIN",
    createdTs: 1720696033021,
    priority: 0,
    eventTtl: 86400,
    jackpotTierName: "MajorJackpot",
    jackpotTierId: "asd",
    amount: 50,
}

const mdsError = {
    type: "error",
    payload: {
        errors: [{
            errorType: "Exception",
            message: "unknown not supported through the realtime channel"
        }]
    }
};

const EmptyGame = () => {
    const dispatch = useDispatch();
    const userId = useSelector(state => state?.session?.userId);
    const [swjInput, setSWJInput] = useState(defaultValue);
    const [messages, setMessages] = useState([]);
    const swjTiers = useSelector(swjTiersSelector);
    const { emitMockJackpotWin, initSocket } = MDS(getMDSEndpoint(), setMessages);
    const [swjErrorSection, setSwjErrorSection] = useState(true);
    const [gameWindowToggleSection, setGameWindowToggleSection] = useState(true);
    const [errorSection, setErrorSection] = useState(true);
    const [swjWinSection, setSwjWinSection] = useState(true);

    const swjToggle = useSelector(swjEnabledSelector);
    const isPartOfSWJExperiment = useSelector(state => state?.amplitudeExperiments?.experiments?.swjEnabled) === "site-wide-jackpots";
    const swjFeatureFlag = useSelector(swjFeatureFlagSelector);
    const [isSwjEnabled, setIsSwjEnabled] = useState(false)

    const handleInputChange = e => {
        setSWJInput(prev => {
            if (!e.target.value) {
                return { ...prev, [e.target.name]: defaultValue[e.target.name] };
            }

            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleTriggerJackpotWin = () => {
        const { jackpotTierId, jackpotTierName, amount, ...rest } = swjInput;

        const obj = {
            ...rest,
            payload: JSON.stringify({
                jackpotTierName,
                jackpotTierId,
                amount,
                jackpotId: "random"
            })
        };

        emitMockJackpotWin(obj);
    };

    useEffect(() => {
        if (swjTiers){
            defaultValue.jackpotTierId = Object?.keys(swjTiers)?.[0];
        }
        defaultValue.userId = userId;
    }, [swjTiers])

    useEffect(() => {
        if (!userId) {
            return;
        }

        initSocket(userId);

        window.top.postMessage({ type: "GC/SET_GAME_LOADED", payload: false }, "*");
    }, [userId]);

    useEffect(() => {
        console.log({ messages });
    }, [messages]);

    useEffect(() => {
        setIsSwjEnabled(swjToggle && (swjFeatureFlag || isPartOfSWJExperiment));
    }, [swjToggle, isPartOfSWJExperiment, swjFeatureFlag]);

    const handleSpinSimulate = () => {
        handleSpinStart();
        setTimeout(() => {
            handleSpinEnd();
        }, 3000);
    };

    const handleSpinStart = () => {
        postMessage({ type: "GC/SET_SPIN_IN_PROGRESS", payload: true });
    };

    const handleSpinEnd = () => {
        postMessage({ type: "GC/SET_SPIN_IN_PROGRESS", payload: false });
    };

    const handleSWJError = (error) => {
        console.log("Trigger SWJ Error: ", error)
        if (isGameWindowDisabled()){
            window.top.postMessage(error, "*");
        } else {
            switch (error.type){
            case "GL/SWJ_OPT_IN_INITIALISATION_FAILURE":
            case "GL/SWJ_OPT_IN_ERROR":
            case "GL/SWJ_OPT_OUT_ERROR":
            case "GL/SWJ_TICKER_INITIALISATION_FAILURE":
            case "GL/SWJ_TICKER_TIMEOUT":
            case "GL/SWJ_MDS_INITIALISATION_FAILURE":
            case "GL/SWJ_MDS_KEEP_ALIVE_FAIL": {
                dispatch(
                    setInGameError({
                        errorCode: error.errorCode,
                        errorMessage: "",
                        errorType: ERROR_TYPE.SWJ_SERVICE_ERROR
                    })
                );
                break;
            }
            case "GL/SWJ_SOCIAL_PROOFING_ERROR":
                console.log("TODO: Social Proofing Error once jackpot ids match");
                break;
            default:{
                console.log("Error type not supported");
                break;
            }
            }

        }
    }

    const postMessage = (body) => {
        window.top.postMessage(body, "*");
    }

    const setError = (type, code, message) => {
        postMessage({type: "GC/SET_PROVIDER_ERROR", payload: {
            errorType: type,
            errorMessage: message || "",
            errorCode: code,
            display: true,
            errorParameters: {}
        }});
    }

    const handleJackpotChange = (event) => {
        defaultValue.jackpotTierId = event.target.value;
    }

    const handleSendAuthTokensIos = () => {
        const { authToken, loginToken } = getAWCookies();

        sendIosTokenRefreshMessage({
            authToken,
            loginToken
        });
    }

    return (<div className="wrapper">
        {isSwjEnabled && <div className="section">
            <p className="section-title" onClick={() => setSwjErrorSection(!swjErrorSection)}> {`SWJ Error Toggles ${swjErrorSection ? "▲" : "▼"}`} </p>
            <div className={`button-wrapper ${swjErrorSection ? "showSection" : ""}`}>
                <button onClick={() => handleSWJError({type: "GL/SWJ_OPT_IN_INITIALISATION_FAILURE", errorCode: "SWJ-001"})} id="swj-opt-in-initialisation-failure"> Opt In Initialisation Failure </button>
                <button onClick={() => handleSWJError({type: "GL/SWJ_OPT_IN_ERROR", errorCode: "SWJ-002"})} id="swj-opt-in-error"> Opt in failure </button>
                <button onClick={() => handleSWJError({type: "GL/SWJ_OPT_OUT_ERROR", errorCode: "SWJ-003"})} id="swj-opt-out-error"> Opt out failure </button>
                {!isIosWrapper() && <button onClick={() => handleSWJError({type: "GL/SWJ_OPT_IN_SYNC_FAILURE", errorCode: "SWJ-004"})} id="swj-opt-in-sync-error"> Opt in Sync Failure </button>}
                <button onClick={() => handleSWJError({type: "GL/SWJ_TICKER_INITIALISATION_FAILURE", errorCode: "SWJ-101"})} id="swj-ticker-initialisation-failure"> Ticker Initialisation Failure </button>
                <button onClick={() => handleSWJError({type: "GL/SWJ_TICKER_TIMEOUT", errorCode: "SWJ-102"})} id="swj-ticker-timeout-error"> Ticker Timeout </button>
                {!isIosWrapper() && <button onClick={() => handleSWJError({type: "GL/SWJ_MDS_INITIALISATION_FAILURE", errorCode: "SWJ-201"})} id="swj-mds-initialisation-failure"> MDS Initialisation Failure </button>}
                {!isIosWrapper() && <button onClick={() => handleSWJError({type: "GL/SWJ_MDS_KEEP_ALIVE_FAIL", errorCode: "SWJ-202"})} id="swj-mds-keep-alive-failure"> MDS Keep Alive Failure (disconnect) </button>}
                {isIosWrapper() && <button onClick={() => handleSWJError({type: "GL/MDS_EVENT", payload: mdsError })} id="swj-mds-error"> MDS Error </button>}
                <button onClick={() => handleSWJError({type: "GL/SWJ_ENABLED_ERROR", errorCode: "SWJ-203"})} id="swj-enabled-error"> SWJ Enabled </button>
                <button onClick={() => handleSWJError({type: "GL/SWJ_DISABLED_ERROR", errorCode: "SWJ-204"})} id="swj-disabled-error"> SWJ Disabled </button>
                <button onClick={() => handleSWJError({type: "GL/SWJ_GAME_ADDED_TO_SWJ_ERROR", errorCode: "SWJ-205"})} id="swj-game-added-error"> Game Added </button>
                <button onClick={() => handleSWJError({type: "GL/SWJ_GAME_REMOVED_TO_SWJ_ERROR", errorCode: "SWJ-206"})} id="swj-game-removed-error"> Game Removed </button>
                <button onClick={() => handleSWJError({type: "GL/SWJ_SOCIAL_PROOFING_ERROR", errorCode: "SWJ-301"})} id="swj-social-proofing-error"> Social Proofing Error </button>
            </div>
        </div>}
        <div className="section">
            <p className="section-title" onClick={() => setGameWindowToggleSection(!gameWindowToggleSection)}> {`Game Window Toggles ${gameWindowToggleSection ? "▲" : "▼"}`} </p>
            <div className={`button-wrapper ${gameWindowToggleSection ? "showSection" : ""}`}>
                <button onClick={() => handleSpinStart()} id="spin-start"> Spin START </button>
                <button onClick={() => handleSpinEnd()} id="spin-stop"> Spin FINISHED </button>
                <button onClick={() => handleSpinSimulate()} id="spin"> Spin (3 seconds) </button>
            </div>
        </div>
        <div className="section">
            <p className="section-title" onClick={() => setErrorSection(!errorSection)}> {`Game Error Toggles ${errorSection ? "▲" : "▼"}`} </p>
            <div className={`button-wrapper ${errorSection ? "showSection" : ""}`}>
                <button onClick={() => setError("catch_all", "FE-GENERIC")} id="error-generic"> Generic Error </button>
                <button onClick={() => setError("balance_too_low", "FE-OOF")} id="error-out-of-funds"> Out of Funds </button>
                <button onClick={() => setError("session_expired", "FE-SE")} id="error-session-expired"> Session Expired </button>
                <button onClick={() => setError("connection_lost", "FE-CL")} id="error-generic-connection-lost"> Connection Lost </button>
                <button onClick={() => setError("game_stopped_working", "FE-GSW")} id="error-game-stopped-working"> Game Stopped Working </button>
                <button onClick={() => setError("too_many_games_open", "FE-TMGO")} id="error-too-many-games-open"> Too Many Games Open </button>
                {isIosWrapper() && <button onClick={() => handleSendAuthTokensIos()} id="error-send-ios-tokens"> (iOS) Send auth tokens </button>}
            </div>
        </div>
        {isSwjEnabled && <div className="section">
            <p className="section-title" onClick={() => setSwjWinSection(!swjWinSection)}> {`SWJ Jackpot Toggle ${swjWinSection ? "▲" : "▼"}`} </p>
            <div className={`button-wrapper ${swjWinSection ? "showSection" : ""}`}>
                <button onClick={() => handleTriggerJackpotWin()} id="swj-trigger-win-button"> Trigger SWJ Win </button>
                <div className="swj-win-data-wrapper">
                    <input name="amount" onChange={handleInputChange} placeholder="amount" defaultValue={defaultValue.amount} id="swj-trigger-win-amount" />
                    <select id="swj-trigger-dropdown" onChange={(event) => handleJackpotChange(event)}>
                        {Object?.keys(swjTiers)?.map(tier => <option key={tier} value={tier} id={`swj-trigger-option-${tier}`} > {swjTiers[tier].name} </option>)}
                    </select>
                </div>
            </div>
        </div>}
    </div>);
};

export default EmptyGame;
