import { FunctionComponent } from 'preact';
import { useEffect } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
    gameExpansionToggleDisplayStateSelector,
    gameWindowExpansionStateDisabled,
    gameWindowExpansionStateSelector,
    gameWindowSWJOptIn
} from '../../selectors/game-window';
import { setGameWindowExpansionState } from '../../redux/actions/game-window';
import { types } from '../../redux/types';
import { postCustomerPreferences } from '../../redux/actions/customer-data/customer-data';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from '../../assets/icons/SWJ/swj-logo.png';
import { useLogTracking } from '../../common/hooks/use-user-tracking/useUserTracking';
import { getAmplitudeKey, MODULES } from '../../common/hooks/use-user-tracking/types';

export const MinMaxGameToggle: FunctionComponent = () => {
    require(`./styles/fanduel-casino/style.scss`);
    const dispatch = useDispatch();

    const isShowing = useSelector(gameExpansionToggleDisplayStateSelector);
    const isOn = useSelector(gameWindowExpansionStateSelector);
    const isSpinInProgress = useSelector(state => state?.['gameWindow']?.spinInProgress);
    const disabled = useSelector(gameWindowExpansionStateDisabled);
    const customerData = useSelector(state => state?.['customerData']);
    const { logTrackingComponent } = useLogTracking();
    const optedIn = useSelector(gameWindowSWJOptIn);
    const optedInOption = Number(optedIn) > 0 ? 'in' : 'out';

    useEffect(() => {
        if (!customerData?.loading && customerData?.preferences && !('showSWJElements' in customerData.preferences)) {
            dispatch({
                type: types.CUSTOMER_FEATURES_RECEIVED,
                payload: { ...customerData, preferences: { ...customerData?.preferences, showSWJElements: true } }
            });
            postCustomerPreferences({ showSWJElements: true });
        }

        if (customerData?.preferences) {
            dispatch(setGameWindowExpansionState(customerData.preferences.showSWJElements));
        }
    }, [customerData]);

    const handleClick = () => {
        if (!disabled && !isSpinInProgress) {
            postCustomerPreferences({ showSWJElements: !isOn });
            dispatch(setGameWindowExpansionState(!isOn));

            logTrackingComponent({
                [getAmplitudeKey('MODULE')]: MODULES.GW,
                [getAmplitudeKey('FDC_JACKPOT_OPT_IN_STATUS')]: `opted_${optedInOption}`,
                [getAmplitudeKey('EVENT')]: `Game Screen ${!isOn ? 'Minimized' : 'Maximized'}`
            });
        }
    };

    const handleKeyDown = (e: any) => {
        if (!disabled && !isSpinInProgress && (e.key === 'Enter' || e.key === ' ')) {
            handleClick();
        }
    };

    return (
        <div
            className={`min-max-toggle ${isOn ? 'on' : 'off'} ${(disabled || isSpinInProgress) && 'disabled'} ${
                (isShowing || (!isOn && !isShowing)) && 'show'
            }`}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
            aria-pressed={isOn}
            aria-disabled={disabled || isSpinInProgress}
        >
            <div className="icon-container">
                <div className="not-show-icon" />
                <div className="icon off-icon">
                    <div className="cancel-icon" />
                    <img className="logo-icon" src={logo} />
                </div>
                <div className="icon on-icon">
                    <img className="logo-icon" src={logo} />
                </div>
                <div className="white-underlay" />
            </div>
            <div className="text-container">
                <div className="text-on">Hide</div>
                <div className="text-off">Show</div>
            </div>
        </div>
    );
};
