import DashboardIcon from '../../../../../assets/icons/GameWindow/DashboardIcon';
import GameHelpIcon from '../../../../../assets/icons/GameWindow/GameHelpIcon';
import PromotionsIcon from '../../../../../assets/icons/GameWindow/PromotionsIcon';
import NotificationsIcon from '../../../../../assets/icons/GameWindow/NotificationsIcon';
import { SHOWOFF_ELEMENTS } from '../../../../../common/hooks/use-showoff/useShowoff';

// add all the possible icons to this switch
const getIcon = (icon, size) => {
    switch (icon) {
    case 'dashboard':
        return <DashboardIcon size={size} />;
    case 'game_help':
        return <GameHelpIcon size={size} />;
    case 'promotions':
        return <PromotionsIcon size={size} />;
    case 'notifications':
        return <NotificationsIcon size={size} />;
    default:
        return null;
    }
};

const addShowOffAttribute = (title) => {
    const attributesMap = new Map([['dashboard', SHOWOFF_ELEMENTS.DASHBOARD_BUTTON], ['game help', SHOWOFF_ELEMENTS.GAME_HELP_BUTTON]])

    return attributesMap.get(title?.toLowerCase());
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const MenuTitle = ({ title, icon, isTitle, highlight, size }) => {
    return (
        <div className={`${isTitle ? 'menu-content-title' : 'nav-item'} ${highlight ? 'highlight' : ''}`} data-showoff={ !isTitle ? addShowOffAttribute(title) : null } data-showoff-notification={'false'}>
            <div className={'title-icon'}>{getIcon(icon, size)}</div>
            <div className={'title'}>{title}</div>
        </div>
    );
};

export default MenuTitle;
