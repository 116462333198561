import { createSelector } from 'reselect';
import { gameWindowConfigSelector, variantSelector } from './variants';

export const menuItemsSelector = createSelector(gameWindowConfigSelector, config => {
    const menuConfig = config?.primary?.show_menu && config?.items ? config.items : [];

    return menuConfig
        .map(menuElem => {
            if (menuElem?.show_menu_item && menuElem?.menu_item_type && menuElem?.menu_item_title) {
                return {
                    title: menuElem.menu_item_title[0].text,
                    type: menuElem.menu_item_type
                };
            }
        })
        .filter(item => !!item);
});

export const dashboardItemsSelector = createSelector(variantSelector, variant => {
    const gameHelpSlice = variant?.find(elem => elem?.slice_type === 'dashboard');

    const dashboardItems = gameHelpSlice?.items ?? [];

    return dashboardItems
        .map(elem => {
            return elem?.card_type
                ? {
                    type: elem.card_type,
                    title: elem?.card_title?.[0]?.text,
                    show: elem?.show_card
                }
                : null;
        })
        .filter(elem => !!elem);
});
