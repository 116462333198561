import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useEffect } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { types } from '../../redux/types';
import { TIMERS_TIMESTAMPS } from '../constants';

dayjs.extend(utc);

const { SERVER_MARK, SESSION_MEASURE } = TIMERS_TIMESTAMPS;

export const useSessionTime = tickerInitialized => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const sessionStartTime = useSelector(state => state?.session?.sessionStartTime);
    const serverTime = useSelector(state => state?.session?.serverTime);
    const sessionFormat = t('timerFormat.sessionTime');

    const sessionStartValue = useMemo(() => {
        if (sessionStartTime) {
            return new Date(sessionStartTime).valueOf();
        }
    }, [sessionStartTime]);
    const serverTimeValue = useMemo(() => {
        if (serverTime) {
            return new Date(serverTime).valueOf();
        }
    }, [serverTime]);

    const updateSessionTime = useCallback(() => {
        if (
            window?.performance?.getEntriesByName?.(SERVER_MARK)?.length > 0 &&
            serverTimeValue !== undefined &&
            sessionStartValue !== undefined
        ) {
            window?.performance?.clearMeasures?.(SESSION_MEASURE);
            window?.performance?.measure?.(SESSION_MEASURE, SERVER_MARK);
            const timeElapsed = window?.performance?.getEntriesByName?.(SESSION_MEASURE)?.[0]?.duration || 0;
            const timeNow = serverTimeValue + timeElapsed;
            dispatch({
                type: types.SET_SESSION_TIME,
                payload: dayjs.utc(timeNow - sessionStartValue).format(sessionFormat)
            });
        }
    }, [dispatch, serverTimeValue, sessionFormat, sessionStartValue]);

    useEffect(() => {
        if (tickerInitialized) {
            const interval = setInterval(updateSessionTime, 1000);

            return () => {
                clearInterval(interval);
                window?.performance?.clearMeasures?.(SESSION_MEASURE);
            };
        }
    }, [serverTimeValue, tickerInitialized, updateSessionTime]);
};
