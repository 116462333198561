import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ExitIcon from '../../../../assets/icons/GameWindow/ExitIcon';

import { showExitPopup } from '../../../../redux/actions/exit-popup';
import { SHOWOFF_ELEMENTS } from '../../../../common/hooks/use-showoff/useShowoff';
import { useCallback, useMemo } from 'preact/hooks';
import { useLogTracking } from '../../../../common/hooks/use-user-tracking/useUserTracking';
import { MODULES, getAmplitudeKey } from '../../../../common/hooks/use-user-tracking/types';
import { useInject } from 'inversify-hooks';
import { DI_SERVICE } from '../../../../dependency-injection/constants';
import { APPTENTIVE_EVENTS } from '../../../../common/constants/game-constants';

const ExitButton = ({ size, direction, style, zoneName }) => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);
    const { logTrackingComponent } = useLogTracking();
    const [sendToApptentive] = useInject(DI_SERVICE.sendToApptentive);

    const { t } = useTranslation();
    const exitGameString = `${t('gameWindow.exit')}`;

    const dispatch = useDispatch();

    const commonPayload = useMemo(() => ({
        [getAmplitudeKey('MODULE')]: MODULES.IN_GAME_HEADER,
        [getAmplitudeKey('ZONE_NAME')]: zoneName
    }),[zoneName]);

    const sendGtmMessage = useCallback(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'In Game Exited',
        });
        sendToApptentive(APPTENTIVE_EVENTS.IN_GAME_EXITED);

        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'In Game Exit Modal Viewed',
            ...commonPayload
        });
    }, [logTrackingComponent, sendToApptentive]);

    return (
        <div
            className={`button-wrapper ${direction} exit-button`}
            data-showoff={SHOWOFF_ELEMENTS.EXIT_BUTTON}
            onClick={() => {
                sendGtmMessage();
                dispatch(showExitPopup());
            }}
            {...{ style }}
        >
            <div className="icon">{<ExitIcon size={size} />}</div>
            <span>{exitGameString}</span>
        </div>
    );
};

export default ExitButton;
