// eslint-disable-next-line no-unused-vars
import { h, Fragment } from 'preact';
import { useCallback } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Map from '../../../../assets/icons/Map/map';
import Info from '../../../../assets/icons/general/info';
import { plcClearError } from '../../../../redux/geo-plc/actions/plc-clear-error';

import { setGeocomplyRetrying, verifyLocationClearError } from '../../../../redux/geo-comply/location/geo-location';
import config from '../../../../../config/config';
import { dismissLauncher } from '../../../../common/helpers/redirect-flows';
import { isRunningInsideWrapper } from '../../../../common/wrapper-bridge-mobile';

import { useGeocomplyHelpers } from '../../../../common/hooks/use-geocomply-flow/use-geocomply-helpers';
import { rgiSelector } from '../../../../selectors/gameContext';
import { RGIS } from '../../../../common/constants';

require(`./styles/${process.env.APPLICATION}/style.scss`);

const LocationError = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const locationError = useSelector(state => state?.geocomply?.location?.error);
    const plcError = useSelector(state => state?.plc?.error);
    const { triggerGeocomply } = useGeocomplyHelpers();
    const rgi = useSelector(rgiSelector);

    console.warn(`locationError:${JSON.stringify(locationError)}, plcError:${plcError}`);

    const isAndroidWrapper = isRunningInsideWrapper();

    const retry = useCallback(() => {
        console.warn('--Retry--');
        dispatch(plcClearError());
        dispatch(verifyLocationClearError());
        dispatch(setGeocomplyRetrying(true));

        if (rgi === RGIS.INCENTIVE_GAMES) {
            triggerGeocomply();
        } else {
            const gameClientFrame = document.querySelector('#casino-game-client');
            gameClientFrame.contentWindow.postMessage(
                {
                    type: 'GL/TRIGGER_GEOLOCATION'
                },
                '*'
            );
        }
    }, [dispatch, rgi, triggerGeocomply]);

    return locationError !== null || plcError !== null ? (
        <Fragment>
            <div className="modal__image">
                <Map />
            </div>

            <div className="modal__header">{t('geocomply.locationError.title')}</div>

            <div className="modal__body">
                <p className="modal__header">{t('geocomply.locationError.description')}</p>
                <p>
                    <Info />
                    {t(`geocomply.locationError.${isAndroidWrapper ? 'androidWrapper.info1' : 'info1'}`)}
                </p>
                <p>
                    <Info />
                    {t(`geocomply.locationError.${isAndroidWrapper ? 'androidWrapper.info2' : 'info2'}`)}
                </p>
                <p>
                    <Info />
                    {t('geocomply.locationError.info3')}
                    <a
                        className="more-details"
                        href={config?.geoComply?.help}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('geocomply.locationError.link')}
                    </a>
                </p>
            </div>
            <div className="modal__footer">
                <div className="modal__buttons">
                    <span className="btn btn--transparent" role="button" onClick={() => dismissLauncher()}>
                        {t('PLCNotInstalled.dismiss')}
                    </span>
                    <span className="btn btn__link btn-main" role="button" onClick={() => retry()}>
                        {t('geocomply.locationError.retry')}
                    </span>
                </div>
            </div>
        </Fragment>
    ) : null;
};

export default LocationError;
