/**
 * Custom hook to determine if a game should be hidden based on customer features and game information.
 *
 * Games are usually available, but for some games, we want to hide them from the web-lobby and search.
 * Only whitelisted users will see those games.
 * Here we are checking if the game is under a feature-key
 * and if the user is whitelisted to see the game.
 * This covers the scenario when someone tries to open a hidden game from a deep-link.
 *
 * @param {Object} customerFeatures.features - The features available to the customer Added from AWS S3 Buckets.
 * @param {Array} gameInfo.feature_keys - An array of feature keys associated with the game (Added from Prismic).
 * @returns {boolean} - Returns true if the game should be hidden, otherwise false.
 */

import { useMemo } from 'preact/hooks';

export const useIsHiddenGame = (customerFeatures, gameInfo) =>
    useMemo(() => {
        let isHiddenGame = false;
        const customerFeaturesKeys = customerFeatures.features || [];
        const gameFeatureKeys = gameInfo?.feature_keys || [];
        const gameFeatureKeyValues = gameFeatureKeys
            .filter(key => key.feature_key?.type === 'feature_key')
            .map(key => key.feature_key?.uid);
        /**
         * Checking if any of the gameFeatureKeyValues are present in customerFeatures.
         * The gameFeatureKeyValues coming from Prismic can have a structure like: `game-launch-allowed` or `game-launch-allowed-123`
         * as Prismic sometimes adds an ID at the end.
         * Considering the customerFeaturesKeys look like: `game-launch-allowed`,
         * we need to check both conditions. This ensures that if a game feature key has an ID appended, it is still correctly
         * identified as a feature that the customer has access to.
         * */
        isHiddenGame =
            gameFeatureKeyValues &&
            gameFeatureKeyValues.some(key => {
                const baseKey = key.split('-').slice(0, -1).join('-');
                return !(customerFeaturesKeys?.includes(baseKey) || customerFeaturesKeys?.includes(key));
            });
        return isHiddenGame;
    }, [customerFeatures, gameInfo]);
