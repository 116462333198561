import { datadogRum } from '@datadog/browser-rum';

import { FetchType, getRequestUid } from '../../../../common/helpers/fetch-helper';
import { getSessionId, getUserId } from '../../../../common/helpers/get-data-unified-modules';
import connectionType from '../../../../common/helpers/connectionType';
import { ddRumAction } from '../../../../common/helpers/datadog-wrapper';
import { ddActionGetUserDetailsReceived } from '../../../../common/datadog/get-user/gu-details-received';
import { DD_USE_CASES } from '../../../../common/datadog/enums';

import { fetchEnd, fetchError, fetchStart } from '../../../fetch/fetch';
import { types } from '../../../types';
import { logTrackingEvent } from '../../../../common/helpers/user-tracking.helpers';
import { gameIdSelector, gameNameSelector } from '../../../../selectors/gameContext';
import { getAmplitudeKey } from '../../../../common/hooks/use-user-tracking/types';
import { gameProviderNameSelector } from '../../../../selectors/game-window';

export const getUserDetailsWeb = () => async (dispatch, getState) => {
    const requestUid = getRequestUid(FetchType.UserSession);

    const sessionId = await getSessionId();

    const gameName = gameNameSelector(getState());
    const gameProviderName = gameProviderNameSelector(getState());
    const gameId = gameIdSelector(getState());

    dispatch(fetchStart(requestUid));
    dispatch({ type: types.GET_USER_DETAILS_REQUEST });

    const userId = await getUserId()

    if (userId) {
        dispatch({
            type: types.GET_USER_DETAILS_RECEIVED,
            payload: { userId, sessionId }
        });
        dispatch(fetchEnd(requestUid));

        ddRumAction(ddActionGetUserDetailsReceived({ userId, sessionId }, DD_USE_CASES.GAME_LAUNCHER));
    } else {
        //TODO - check is we still need to dispatch this
        const error = "[ACCOUNT_UM] - Cannot get user data";
        dispatch({
            type: types.GET_USER_DETAILS_FAILED,
            payload: { name: error }
        });
        dispatch(fetchError(requestUid));



        datadogRum.addError(
            new Error(error),
            Object.assign(
                {
                    type: '[GET_USER_DETAILS]: ERROR',
                    connectionType: connectionType()
                },
                error
            ),
        );

        logTrackingEvent({
            [getAmplitudeKey('GAME_ID')]: gameId,
            [getAmplitudeKey('GAME_NAME')]: gameName,
            [getAmplitudeKey('GAME_PROVIDER')]: gameProviderName,
            [getAmplitudeKey('EVENT')]: 'Game Launch Failed',
            [getAmplitudeKey('COMPONENT')]: 'a&w',
            [getAmplitudeKey('PAGE_NAME')]: 'game_window',
            [getAmplitudeKey('FULL_URL')]: window.location.href,
            [getAmplitudeKey('PAGE_PATH')]: window.location.pathname
        });
    }
};

export default getUserDetailsWeb;
