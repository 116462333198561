import { useInject } from 'inversify-hooks';
import { DI_SERVICE } from '../../../dependency-injection/constants';
import { useCallback, useEffect } from 'preact/hooks';
import { getXsellGeocomplyCookie } from '../../helpers/cookies';

const useGeocomplyFlowAndroid = () => {
    const gameClientFrame = document.querySelector('#casino-game-client');

    const [useGeocomplyLicense] = useInject(DI_SERVICE.useGeocomplyLicense);
    const [useGeocomplyPlc] = useInject(DI_SERVICE.useGeocomplyPlc);
    const [useGeolocationTrigger] = useInject(DI_SERVICE.useGeolocationTrigger);
    const [useGeolocationVerify] = useInject(DI_SERVICE.useGeolocationVerify);

    const emitTokenUpdatedMessage = useCallback(() => {
        const geocomplyToken = getXsellGeocomplyCookie();

        gameClientFrame?.contentWindow?.postMessage(
            {
                type: 'GL/GEOLOCATION_ANDROID_TOKEN_UPDATED',
                payload: {
                    geocomplyToken
                }
            },
            '*'
        );
    }, [gameClientFrame]);

    useEffect(() => {
        window.addEventListener('geolocationTokenUpdated', () => emitTokenUpdatedMessage());

        return () => window.removeEventListener('geolocationTokenUpdated', emitTokenUpdatedMessage);
    }, [emitTokenUpdatedMessage]);

    useGeocomplyLicense();
    const { connected } = useGeocomplyPlc();
    useGeolocationTrigger({ connected });
    useGeolocationVerify();
};

export default useGeocomplyFlowAndroid;
