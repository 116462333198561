import { useCallback, useEffect } from 'preact/hooks';
import { BrowserOptions, EnrichmentPlugin, Event } from '@amplitude/analytics-types';
import * as amplitude from '@amplitude/analytics-browser';
// eslint-disable-next-line no-duplicate-imports
import { setDeviceId } from '@amplitude/analytics-browser';
import { v4 as uuid } from 'uuid';
import Cookie from 'js-cookie';
import { useInject } from 'inversify-hooks';
import { useSelector } from 'react-redux';

import useGtm from '../use-gtm';
import { getAmplitudeKey, Undef, OnEventType } from './types';
import { regionSelector } from '../../../selectors/gameContext';
import { getCookieValue } from '../../helpers/cookies';
import {AMPLITUDE_COOKIES, TIME_CONSTANTS} from '../../constants';
import { useOrientation } from '../useOrientation';
import config from '../../../../config/config';
import {
    getAndroidDistribution,
    getDevice,
    getAndroidAppVersion,
    launchedFrom,
    logTrackingEvent
} from '../../helpers/user-tracking.helpers';
import { getBrand } from '../../helpers/brand';
import { getAppPlatform } from "../../helpers/app-platform";
import { PLATFORM } from "../../constants/game-constants";
import { DI_SERVICE } from '../../../dependency-injection/constants';
import { APPTENTIVE_EVENTS } from '../../../common/constants/game-constants';

const defaultOptions: BrowserOptions = {
    defaultTracking: {
        attribution: {
            resetSessionOnNewCampaign: true
        },
        pageViews: false,
        sessions: false,
        fileDownloads: false,
        formInteractions: false
    },
    identityStorage: 'localStorage',
    minIdLength: 1
};

const apiKey = config?.amplitude?.apiKey;


const devicePlugin = (): EnrichmentPlugin => {
    return {
        name: 'addDevice',
        execute: async (event: Event) => ({
            ...event,
            platform: getDevice()
        })
    };
};

const changeCasePlugin = (): EnrichmentPlugin => {
    return {
        name: 'changeCase',
        execute: async (event: Event) => {
            const newName = event.event_type
                .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
                .replace(/[-_]+(.)/g, (_, c) => ` ${ c.toUpperCase()}`);

            return {
                ...event,
                event_type: newName
            };
        }
    };
};

const addGLToEventTypePlugin = (): EnrichmentPlugin => {
    return {
        name: 'addGLToEventType',
        execute: async (event: Event) => ({
            ...event,
            event_type: !event.event_type.startsWith('GL ') ? `GL ${event.event_type}` : event.event_type
        })
    };
};

const addXsellPrefixToEventTypePlugin = (): EnrichmentPlugin => {
    return {
        name: 'addXsellPrefixToEventType',
        execute: async (event: Event) => {
            const prefix = 'CAS XS' as const;
            return ({
                ...event,
                event_type: !event.event_type.startsWith(`${prefix} `) ? `${prefix} ${event.event_type}` : event.event_type
            });
        }
    };
}

export const useUserTrackingSetup = (initialized: boolean, setInitialized: () => void) => {
    useGtm();

    const { deviceType } = useOrientation();

    const userId = useSelector(state => state?.['session']?.userId);
    const amplitudeId = getCookieValue(AMPLITUDE_COOKIES.AMPLITUDE_ID);
    const sessionId = getCookieValue(AMPLITUDE_COOKIES.AMP_SESSION_ID);
    const [sendToApptentive] = useInject<OnEventType>(DI_SERVICE.sendToApptentive);

    const accountId: Undef<string> = useSelector(
        state => state?.['userContextReducer']?.userContextData?.accountSettings?.accountID
    );
    const loginStatus: Undef<string> = useSelector(
        state => state?.['userContextReducer']?.userContextData?.accountSettings?.loggedIn
    );
    const region: Undef<string> = useSelector(regionSelector);

    const getInitOptions = useCallback((params: { deviceId: string; sessionId: number }) => {
        return Object.keys(params).reduce(
            (acc, key) => {
                if (params[key]) {
                    return { ...acc, [key]: params[key] };
                }
                return acc;
            },
            { ...defaultOptions }
        );
    }, []);

    useEffect(() => {
        if (!initialized && userId) {
            setInitialized();
            amplitude.init(
                apiKey,
                userId || null,
                getInitOptions({ deviceId: amplitudeId, sessionId: parseInt(sessionId, 10) })
            );

            amplitude.add(changeCasePlugin());
            amplitude.add(addGLToEventTypePlugin());
            amplitude.add(devicePlugin())

            if (getAppPlatform() === PLATFORM.Xsell) {
                amplitude.add(addXsellPrefixToEventTypePlugin());
            }

            if (!amplitudeId) {
                const deviceId = uuid();
                setDeviceId(deviceId);
                Cookie.set(AMPLITUDE_COOKIES.AMP_DEVICE_ID, deviceId, {expires: TIME_CONSTANTS.YEAR, path: '/', domain: config.rootDomain});
            }
        }
    }, [userId, amplitudeId, sessionId, initialized, setInitialized, getInitOptions]);

    useEffect(() => {
        if (initialized) {
            const payload = {
                [getAmplitudeKey('ACCOUNT_ID')]: accountId,
                [getAmplitudeKey('ANDROID_DISTRIBUTION_METHOD')]: getAndroidDistribution(),
                [getAmplitudeKey('LOGIN_STATUS')]: loginStatus ? 'logged_in' : 'logged_out',
                [getAmplitudeKey('EVENT')]: 'Screen Load',
                [getAmplitudeKey('BRAND')]: getBrand(),
                [getAmplitudeKey('JURISDICTION')]: region?.toUpperCase(),
                [getAmplitudeKey('ORIENTATION')]: deviceType,
                [getAmplitudeKey('APP_VERSION')]: getAndroidAppVersion(),
                [getAmplitudeKey('LAUNCHED_FROM')]: launchedFrom()
            } as const;
            logTrackingEvent(payload, false);
            sendToApptentive(APPTENTIVE_EVENTS.GL_SCREEN_LOAD);
        }
    }, [initialized]);
};
