import { types } from '../../types';

export const initialState = {
    displayModal: false,
    redirectMsg: ''
};

const exitPopupReducer = (state = initialState, action) => {
    switch (action.type) {
    case types.SHOW_EXIT_POPUP:
        return {
            displayModal: true,
            redirectMsg: action.redirectMsg
        };
    case types.HIDE_EXIT_POPUP:
        return {
            displayModal: false,
            redirectMsg: ''
        };
    default: {
        return state;
    }
    }
};

export default exitPopupReducer;
