import { h } from 'preact';
import { useState, useEffect, useRef, useMemo } from 'preact/hooks';
import Lottie, { LottieRef } from 'lottie-react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { datadogLogs } from '@datadog/browser-logs';
import { types } from '../../../../redux/types';
import { settleJackpotWin } from '../../../../redux/reducers/site-wide-jackpot/site-wide-jackpot';
import { useDispatch, useSelector } from 'react-redux';
import { useGameClientProxy } from '../../../Launcher/GameClient/hooks/useGameClientProxy';
import { swjSoundSelector, swjTiersSelector, swjWheelIdleSelector, winScreenLogoSelector } from '../../../../selectors/site-wide-jackpot';
import { providerSelector } from '../../../../selectors/gameContext';
import { useInject } from 'inversify-hooks';
import { DI_SERVICE } from '../../../../dependency-injection/constants';
import { useLogTracking } from '../../../../common/hooks/use-user-tracking/useUserTracking';
import isEqual from 'lodash/isEqual';
import { getAmplitudeKey, MODULES } from '../../../../common/hooks/use-user-tracking/types';
import { gameWindowSWJOptIn } from '../../../../selectors/game-window';


export enum WinTierEnum {
    MINI = 'MINI',
    MINOR = 'MINOR',
    MEGA = 'MEGA',
    MAJOR = 'MAJOR'
}

const SpinningAnimationsBackgroudDurationRecord: Record<WinTierEnum, number> = {
    [WinTierEnum.MINI]: 6500,
    [WinTierEnum.MINOR]: 6500,
    [WinTierEnum.MEGA]: 6500,
    [WinTierEnum.MAJOR]: 5500,
}

export interface WonJackpotData {
    name: WinTierEnum,
    shortName?: string,
    amount: number,
    win_particles_animation: object | undefined,
    win_spin_animation: object | undefined,
}

interface JackpotWinningAnimationProps {
    wonJackpot: WonJackpotData
    onJackpotWinningDisplayed?: () => void;
}

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const getNomarlizedTierName = (name= '') => {
    const winTier = name?.toUpperCase();
    let normalizedName: WinTierEnum | undefined;
    for (const tier of Object.values(WinTierEnum)) {
        if (winTier.includes(tier)) {
            normalizedName = WinTierEnum[tier as keyof typeof WinTierEnum];
        }
    }

    return normalizedName;
}

const fetchAnimation = async (url: string | undefined) => {
    if (!url) {
        return null;
    }
    try {
        const response = await fetch(url);
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        console.error('Error fetching animation:', error);
    }
    return null;
};
// FIXME: cognitive complexity
// eslint-disable-next-line sonarjs/cognitive-complexity
export const JackpotWinningAnimation = ({ wonJackpot, onJackpotWinningDisplayed }: JackpotWinningAnimationProps) => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);
    const [backgroundAnimationsRecord, setBackgroundAnimationsRecord] = useState<Record<WinTierEnum, any>>(undefined);
    const [spinningAnimationsRecord, setSpinningAnimationsRecord] = useState<Record<WinTierEnum, any>>(undefined);
    const [wheelIdleRecord, setWheelIdleRecord] = useState(undefined);
    const [wonJackpotData, setWonJackpotData] = useState<WonJackpotData>(undefined);
    const [isSpinning, setIsSpinning] = useState(false);
    const [showCongrats, setShowCongrats] = useState(false);
    const [showBackgroundAnimation, setShowBackgroundAnimation] = useState(false);
    const [showOverlay, setShowOverlay] = useState(true);
    const lottieRef = useRef(null);
    const { logTrackingComponent } = useLogTracking();
    const prevWonJackpotData = useRef(null);
    const gameProvider = useSelector(providerSelector);
    const [getBalanceData] = useInject(DI_SERVICE.balanceData);
    const backgroundLottieRefs = useRef({
        MINI: useRef(null),
        MINOR: useRef(null),
        MAJOR: useRef(null),
        MEGA: useRef(null),
    });
    const [playingBackgroundAnimation, setPlayingBackgroundAnimation] = useState<WinTierEnum | null>(null);
    const jackpotAudioRef = useRef<HTMLAudioElement | null>(null);
    const dispatch = useDispatch();
    const {
        autoSpinStop,
        autoSpinResume,
        reloadBalance
    } = useGameClientProxy();
    const isSpinInProgress = useSelector(state => (state as any)?.gameWindow?.spinInProgress);
    const swjWinningSound = useSelector(swjSoundSelector);
    const swjWheelIdleUrl = useSelector(swjWheelIdleSelector);
    const swjTiers = useSelector(swjTiersSelector);
    const optedIn = useSelector(gameWindowSWJOptIn);
    const optedInOption = Number(optedIn) > 0 ? 'in' : 'out';
    const winLogoImgUrl = useSelector(winScreenLogoSelector);

    useEffect(() => {
        if (wonJackpot && !isSpinInProgress) {
            if (!wonJackpot.name) {
                setWonJackpotData(undefined);
            }

            const normalizedName: WinTierEnum | undefined = getNomarlizedTierName(
                wonJackpot?.shortName || wonJackpot.name
            );
            if (!normalizedName) {
                setWonJackpotData(undefined);
            }
            autoSpinStop();
            setWonJackpotData({
                name: normalizedName as WinTierEnum,
                amount: wonJackpot.amount,
                win_particles_animation: wonJackpot.win_particles_animation,
                win_spin_animation: wonJackpot.win_spin_animation
            });
        }
    }, [wonJackpot, isSpinInProgress]);

    useEffect(() => {
        jackpotAudioRef.current = new Audio(swjWinningSound);
        jackpotAudioRef.current.load();
    }, []);

    const commonPayload = useMemo(() => ({
        [getAmplitudeKey('FDC_JACKPOT_OPT_IN_STATUS')]: `opted_${optedInOption}`,
        [getAmplitudeKey('FDC_JACKPOT_WON_TIER')]: wonJackpotData?.name?.toLowerCase(),
        [getAmplitudeKey('FDC_JACKPOT_WON_AMOUNT')]: wonJackpotData?.amount,
    }),[wonJackpotData, optedInOption]);

    useEffect(() => {
        if (jackpotAudioRef.current) {
            if (isSpinning) {
                const durationInSeconds = (lottieRef as LottieRef)?.current.getDuration();
                console.log('Playing Animation for', durationInSeconds * 1000);

                (lottieRef as LottieRef)?.current?.goToAndPlay(0, true);
                jackpotAudioRef.current.play();

                setTimeout(() => {
                    handleAnimationComplete();
                }, durationInSeconds * 1000);
                return;
            }
            (lottieRef as LottieRef)?.current?.stop();
        }
    }, [isSpinning]);

    useEffect(() => {
        if (showBackgroundAnimation && wonJackpotData?.name) {
            if (showBackgroundAnimation && backgroundAnimationsRecord[wonJackpotData.name]) {
                (backgroundLottieRefs.current[wonJackpotData.name] as LottieRef)?.current?.setSubframe(false);
                (backgroundLottieRefs.current[wonJackpotData.name] as LottieRef)?.current?.goToAndStop(0, true);
                (backgroundLottieRefs.current[wonJackpotData.name] as LottieRef)?.current?.play();
                setPlayingBackgroundAnimation(wonJackpotData.name);
            } else {
                (backgroundLottieRefs.current[wonJackpotData.name] as LottieRef)?.current?.stop();
                setPlayingBackgroundAnimation(null);
            }
        }
    }, [backgroundAnimationsRecord, showBackgroundAnimation, wonJackpotData?.name]);

    useEffect(() => {
        if (wonJackpotData && !isEqual(wonJackpotData, prevWonJackpotData.current)) {
            logTrackingComponent({
                [getAmplitudeKey('EVENT')]: 'FDC Jackpots Win Page Viewed',
                [getAmplitudeKey('PAGE_NAME')]: 'fdc_jackpots_win',
                [getAmplitudeKey('MODULE')]: MODULES.GW,
                ...commonPayload
            });
            prevWonJackpotData.current = wonJackpotData;
        }
    }, [wonJackpotData]);

    useEffect(() => {
        const loadAnimations = async () => {
            const backgroundAnimations= {};
            const spinningAnimations= {};
            let swjWheelIdle = {};
            for (const tier of Object.values(swjTiers)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const normalizedName: WinTierEnum | undefined = getNomarlizedTierName(tier?.tierName);
                if (Object.values(WinTierEnum).includes(normalizedName as WinTierEnum)) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const winParticlesUrl = tier.win_particles_animation;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const winSpinUrl = tier.win_spin_animation;

                    const particlesAnimationData = await fetchAnimation(winParticlesUrl);
                    const spinAnimationData = await fetchAnimation(winSpinUrl);
                    const wheelIdleAnimationData = await fetchAnimation(swjWheelIdleUrl);

                    backgroundAnimations[normalizedName as WinTierEnum] = particlesAnimationData ?? null;
                    spinningAnimations[normalizedName as WinTierEnum] = spinAnimationData ?? null;
                    swjWheelIdle = wheelIdleAnimationData ?? null;
                }
            }

            setBackgroundAnimationsRecord(backgroundAnimations as Record<WinTierEnum, any>);
            setSpinningAnimationsRecord(spinningAnimations as Record<WinTierEnum, any>);
            setWheelIdleRecord(swjWheelIdle);
        };

        loadAnimations();
    }, [swjTiers]);

    useEffect(() => {
        if (lottieRef.current && spinningAnimationsRecord) {
            lottieRef.current.goToAndStop(0, true);
        }
    }, [spinningAnimationsRecord]);

    useEffect(() => {
        datadogLogs.logger.info('[GW][SWJ]jackpots_win_dialog_did_appear', {});
    })

    const handleSpinClick = () => {
        setShowOverlay(false);
        setTimeout(() => {
            setIsSpinning(true);
        }, 300);

        setTimeout(() => {
            setShowBackgroundAnimation(true)
        }, SpinningAnimationsBackgroudDurationRecord[wonJackpotData.name]);

        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'FDC Jackpots Win Page CTA Clicked',
            [getAmplitudeKey('LINK_TEXT')]: 'spin',
            ...commonPayload
        });
    };

    const handleAnimationComplete = () => {
        setTimeout(() => {
            setShowCongrats(true);
            setIsSpinning(false);
        }, 300);
    };

    const exitJackpot = () => {
        datadogLogs.logger.info('[GW][SWJ]jackpots_win_dialog_did_disappear', {});

        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'FDC Jackpots Win Page CTA Clicked',
            [getAmplitudeKey('LINK_TEXT')]: 'back_to_game',
            ...commonPayload
        });

        dispatch({
            type: types.SET_SWJ_JACKPOT_DATA,
            payload: {}
        });

        if (gameProvider.toLowerCase() === "nyx" || gameProvider.toLowerCase() === "lnw") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dispatch(getBalanceData(false, (balance) => {
                reloadBalance(balance);
            }));
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            reloadBalance();
        }

        setWonJackpotData(undefined);
        dispatch(settleJackpotWin());
        autoSpinResume();

        if (onJackpotWinningDisplayed) {
            onJackpotWinningDisplayed();
        }
    }

    if (isSpinInProgress || !wonJackpotData || !backgroundAnimationsRecord || !spinningAnimationsRecord) {
        return null;
    }

    return (
        <div className="jackpot-animations-container">
            <div className="jackpot-logo">
                <img src={winLogoImgUrl} data-testid="jackpot-logo" alt="win-screen-jackpot-logo" className="jackpot-logo-alternate-img" />
            </div>

            <div className={`idle-state ${!isSpinning && !showCongrats ? 'show' : ''}`} data-testid="idle-state">
                <Lottie className="iddle-animation-wrapper" animationData={wheelIdleRecord} loop={true} />
                <div className={`iddle-overlay ${showOverlay ? 'slide-in' : 'slide-out'}`}>
                    <p>
                        Spin the wheel to reveal <br /> your jackpot win!
                    </p>
                    <div className="pulse-button-wrapper">
                        <button
                            data-testid="pulse-button"
                            className="pulse-button"
                            onClick={handleSpinClick}
                        />
                    </div>
                </div>
            </div>


            <div className={`spinning-state ${isSpinning || showCongrats ? 'show' : ''}`} data-testid="spinning-state">
                <div className="background-animation">
                    {backgroundAnimationsRecord[WinTierEnum.MINI] && (
                        <Lottie
                            className={`bg-animation-wrapper mini ${playingBackgroundAnimation === WinTierEnum.MINI ? 'playing' : ''}`}
                            animationData={backgroundAnimationsRecord[WinTierEnum.MINI]}
                            loop={true}
                            autoPlay={false}
                            lottieRef={backgroundLottieRefs.current.MINI}
                            data-testid="lottie-mini"
                        />
                    )}
                    {wonJackpotData.name === WinTierEnum.MINOR && <Lottie
                        className={`bg-animation-wrapper minor ${playingBackgroundAnimation === WinTierEnum.MINOR ? 'playing' : ''}`}
                        animationData={backgroundAnimationsRecord[WinTierEnum.MINOR]}
                        loop={true}
                        autoPlay={false}
                        lottieRef={backgroundLottieRefs.current.MINOR}
                        data-testid="lottie-minor"
                    />}
                    {wonJackpotData.name === WinTierEnum.MAJOR && <Lottie
                        className={`bg-animation-wrapper major ${playingBackgroundAnimation === WinTierEnum.MAJOR ? 'playing' : ''}`}
                        animationData={backgroundAnimationsRecord[WinTierEnum.MAJOR]}
                        loop={true}
                        autoPlay={false}
                        lottieRef={backgroundLottieRefs.current.MAJOR}
                        data-testid="lottie-major"
                    />}
                    {wonJackpotData.name === WinTierEnum.MEGA && <Lottie
                        className={`bg-animation-wrapper mega ${playingBackgroundAnimation === WinTierEnum.MEGA ? 'playing' : ''}`}
                        animationData={backgroundAnimationsRecord[WinTierEnum.MEGA]}
                        loop={true}
                        autoPlay={false}
                        lottieRef={backgroundLottieRefs.current.MEGA}
                        data-testid="lottie-mega"
                    />}
                </div>
                <Lottie
                    className="spin-animation-wrapper"
                    animationData={spinningAnimationsRecord[wonJackpotData.name]}
                    loop={false}
                    autoPlay={false}
                    lottieRef={lottieRef}
                />

                <div className={`congrats-overlay ${showCongrats ? 'slide-in' : ''}`}>
                    <div className="text-wrapper">
                        <div>You won a</div>
                        <div>{wonJackpotData.name.toUpperCase()} JACKPOT</div>
                        <div>{USDollar.format(wonJackpotData.amount)}</div>
                        <div>Winnings are now <br /> in your account!</div>
                        <div>*Subject to federal and state withholding requirements</div>
                    </div>
                    <div className="button-wrapper">
                        <button onClick={exitJackpot}>Back to game</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
